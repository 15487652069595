import { renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink",
  version: "1.1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("svg", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default")
  ]))
}