
import { computed, defineComponent, ref, watch } from 'vue'
import { MutationTypes, useStore } from '@/store'
import { PPTImageElement, PPTShapeElement } from '@/types/slides'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

export default defineComponent({
  name: 'element-flip',
  setup() {
    const store = useStore()
    const handleElement = computed<PPTImageElement | PPTShapeElement>(() => store.getters.handleElement)

    const flip = ref({
      x: 0,
      y: 0,
    })

    watch(handleElement, () => {
      if (!handleElement.value || !['image', 'shape'].includes(handleElement.value.type)) return

      if (handleElement.value.flip) {
        flip.value = {
          x: handleElement.value.flip.x || 0,
          y: handleElement.value.flip.y || 0,
        }
      }
      else flip.value = { x: 0, y: 0 }
    }, { deep: true, immediate: true })

    const { addHistorySnapshot } = useHistorySnapshot()

    const updateFlip = (value: number) => {
      const props = { flip: value }
      store.commit(MutationTypes.UPDATE_ELEMENT, { id: handleElement.value.id, props })
      addHistorySnapshot()
    }

    return {
      flip,
      updateFlip,
    }
  },
})
