
import { computed, PropType, defineComponent, getCurrentInstance, onMounted, nextTick } from 'vue'
import { useStore } from '@/store'
import { Slide } from '@/types/slides'
import { VIEWPORT_SIZE } from '@/configs/canvas'
import useSlideBackgroundStyle from '@/hooks/useSlideBackgroundStyle'

import ScreenElement from './ScreenElement.vue'

export default defineComponent({
  name: 'screen-slide',
  components: {
    ScreenElement,
  },
  props: {
    slide: {
      type: Object as PropType<Slide>,
      required: true,
    },
    scale: {
      type: Number,
      required: true,
    },
    animationIndex: {
      type: Number,
      default: -1,
    },
  },
  setup(props) {
    const store = useStore()
    const viewportRatio = computed(() => store.state.viewportRatio)

    const background = computed(() => props.slide.background)
    const { backgroundStyle } = useSlideBackgroundStyle(background)
    
    const getQueryVariable: any = (variable: any) => {
      const query = window.location.search.substring(1)
      const vars = query.split('&')
      for (let i = 0; i < vars.length; i ++) {
        const pair = vars[i].split('=')
        if (pair[0] === variable) {
          return pair[1]
        }
      }
      return (false)
    }
    // const targetWiondow: any = window.parent
    // targetWiondow.postMessage('iframe预览加载成功', '*')

    const preview = getQueryVariable('preview')
    // 独播
    const mitter = getCurrentInstance()?.appContext.config.globalProperties.mitter
    onMounted(() => {
      mitter.on('onlyPlaySlideshow', (e: any) => {
        props.slide.elements.forEach((item) => {
          if (item.type === 'video' && item.id !== e) {
            nextTick(() => {
              // const videoo = (document.getElementById( `video${e}_html5_api`)as HTMLInputElement)
              const videoOnly = <HTMLVideoElement>document.getElementById(`video${item.id}_html5_api`)
              videoOnly.pause()
            })
          }
        })
      })

      mitter.on('onlyPlayAudioScreen', (e: any) => {
        props.slide.elements.forEach((item, index) => {
          if (item.type == 'audio' && index != e) {
            nextTick(() => {
              // const videoo = (document.getElementById( `video${e}_html5_api`)as HTMLInputElement)
              const audioOnly = <HTMLVideoElement>document.getElementsByClassName('audioPPT')[index]
              if (audioOnly) {
                audioOnly.pause()
              }
            })
          }
        })
      })
    })

    return {
      backgroundStyle,
      VIEWPORT_SIZE,
      viewportRatio,
      preview
    }
  },
})
