
import { computed, defineComponent, onMounted, onUnmounted, provide, ref, reactive, getCurrentInstance, nextTick } from 'vue'
import throttle from 'lodash/throttle'
import { MutationTypes, useStore } from '@/store'
import { Slide } from '@/types/slides'
import { VIEWPORT_SIZE } from '@/configs/canvas'
import { KEYS } from '@/configs/hotkey'
import { ContextmenuItem } from '@/components/Contextmenu/types'
import { isFullscreen } from '@/utils/fullscreen'
import useScreening from '@/hooks/useScreening'

import { message } from 'ant-design-vue'

import ScreenSlide from './ScreenSlide.vue'
import SlideThumbnails from './SlideThumbnails.vue'
import WritingBoardTool from './WritingBoardTool.vue'
import { RectangularCircularConnection } from '@icon-park/vue-next'

export default defineComponent({
  name: 'screen',
  components: {
    ScreenSlide,
    SlideThumbnails,
    WritingBoardTool,
  },
  setup() {
    const store = useStore()
    const slides = computed(() => store.state.slides)
    const slideIndex = computed(() => store.state.slideIndex)
    const viewportRatio = computed(() => store.state.viewportRatio)
    const currentSlide = computed<Slide>(() => store.getters.currentSlide)

    const slideWidth = ref(0)
    const slideHeight = ref(0)

    const scale = computed(() => slideWidth.value / VIEWPORT_SIZE)

    const showPageNumber = ref(false)

    const slideThumbnailModelVisible = ref(false)

    const writingBoardToolVisible = ref(false)

    const getQueryVariable: any = (variable: any) => {
      const query = window.location.search.substring(1)
      const vars = query.split('&')
      for (let i = 0; i < vars.length; i ++) {
        const pair = vars[i].split('=')
        if (pair[0] === variable) {
          return pair[1]
        }
      }
      return (false)
    }

    const preview = getQueryVariable('preview')

    // 计算和更新幻灯片内容的尺寸（按比例自适应屏幕）
    const setSlideContentSize = () => {
      const winWidth = document.body.clientWidth
      const winHeight = document.body.clientHeight
      let width, height

      if (winHeight / winWidth === viewportRatio.value) {
        width = winWidth
        height = winHeight
      }
      else if (winHeight / winWidth > viewportRatio.value) {
        width = winWidth
        height = winWidth * viewportRatio.value
      }
      else {
        width = winHeight / viewportRatio.value
        height = winHeight
      }
      slideWidth.value = width
      slideHeight.value = height
    }
    // 窗口尺寸变化监听：窗口发生变化时更新幻灯片的大小
    // 如果退出了全屏，需要返回到编辑模式
    const { exitScreening } = useScreening()

    const windowResizeListener = () => {
      setSlideContentSize()
      if (!isFullscreen()) {
        exitScreening()
        sessionStorage.setItem('isSlideshow', '不允许刷新')
      }
    }
    const mitter = getCurrentInstance()?.appContext.config.globalProperties.mitter
    const closeWindow = () => {
        exitScreening()
        mitter.emit('switchPage')
    }
    onMounted(() => {
      window.addEventListener('resize', windowResizeListener)
      setSlideContentSize()
    })
    onUnmounted(() => {
      window.removeEventListener('resize', windowResizeListener)
    })

    // 当前页的元素动画列表和当前执行到的位置
    const animations = computed(() => currentSlide.value.animations || [])
    const animationIndex = ref(0)

    // 执行元素的入场动画
    const runAnimation = () => {
      const prefix = 'animate__'
      const animation = animations.value[animationIndex.value]
      animationIndex.value += 1

      const elRef = document.querySelector(`#screen-element-${animation.elId} [class^=base-element-]`) as HTMLElement
      if (elRef) {
        if(animation.isEnter === true){
            elRef.style.opacity = '1'
          }
        const animationName = `${prefix}${animation.type}`
        elRef.classList.add(`${prefix}animated`, animationName)

        const handleAnimationEnd = () => {
          if(animation.isEnter === false){
            elRef.style.opacity = '0'
          }
          elRef.classList.remove(`${prefix}animated`, animationName)
        }
        elRef.addEventListener('animationend', handleAnimationEnd, { once: true })
      }
    }

    // 关闭自动播放
    const autoPlayTimer = ref(0)
    const closeAutoPlay = () => {
      if (autoPlayTimer.value) {
        clearInterval(autoPlayTimer.value)
        autoPlayTimer.value = 0
      }
    }
    onUnmounted(closeAutoPlay)

    // 向上/向下播放
    // 遇到元素动画时，优先执行动画播放，无动画则执行翻页
    // 向上播放遇到动画时，仅撤销到动画执行前的状态，不需要反向播放动画
    const execPrev = () => {
      if (animations.value.length && animationIndex.value > 0) {
        animationIndex.value -= 1
      }
      else if (slideIndex.value > 0) {
        store.commit(MutationTypes.UPDATE_SLIDE_INDEX, slideIndex.value - 1)
        const lastIndex = animations.value ? animations.value.length : 0
        animationIndex.value = lastIndex
      }
      else {
        message.success('已经是第一页了')
      }
      // if (document.getElementsByTagName('video').length !== 0 ) {
      //   for (let index = 0; index < document.getElementsByTagName('video').length; index++) {
      //     document.getElementsByTagName('video')[index].pause()
      //   }
      // }
    }
    const execNext = () => {
      if (animations.value.length && animationIndex.value < animations.value.length) {
        runAnimation()
      }
      else if (slideIndex.value < slides.value.length - 1) {
        store.commit(MutationTypes.UPDATE_SLIDE_INDEX, slideIndex.value + 1)
        animationIndex.value = 0
      }
      else {
        message.success('已经是最后一页了')
        closeAutoPlay()
      }
      // if (document.getElementsByTagName('video').length !== 0 ) {
      //   for (let index = 0; index < document.getElementsByTagName('video').length; index++) {
      //     document.getElementsByTagName('video')[index].pause()
      //   }
      // }
    }

    // 自动播放
    const autoPlay = () => {
      closeAutoPlay()
      message.success('开始自动放映')
      autoPlayTimer.value = setInterval(execNext, 2500)
    }

    // 鼠标滚动翻页
    const mousewheelListener = throttle(function(e: WheelEvent) {
      if (e.deltaY < 0) execPrev()
      else if (e.deltaY > 0) execNext()
    }, 100, { leading: true, trailing: false })

    // 快捷键翻页
    const keydownListener = (e: KeyboardEvent) => {
      const key = e.key.toUpperCase()
      if (key === KEYS.UP || key === KEYS.LEFT) execPrev()
      else if (
        key === KEYS.DOWN || 
        key === KEYS.RIGHT ||
        key === KEYS.SPACE || 
        key === KEYS.ENTER
      ) execNext()
    }

    // 切换到上一张/上一张幻灯片（无视元素的入场动画）
    const turnPrevSlide = () => {
      store.commit(MutationTypes.UPDATE_SLIDE_INDEX, slideIndex.value - 1)
      animationIndex.value = 0
    }
    const turnNextSlide = () => {
      store.commit(MutationTypes.UPDATE_SLIDE_INDEX, slideIndex.value + 1)
      animationIndex.value = 0
    }

    // 切换幻灯片到指定的页面
    const turnSlideToIndex = (index: number) => {
      slideThumbnailModelVisible.value = false
      store.commit(MutationTypes.UPDATE_SLIDE_INDEX, index)
      animationIndex.value = 0
    }

    onMounted(() => {
      
      // document.addEventListener('keydown', keydownListener)
      window.addEventListener('message', (event: any) => {
        if(event.origin == 'https://courseware.brimfar.online') {
          nextTick(() => {
            if(event.data.type == 'left' || event.data.type == 'init') {
              store.commit(MutationTypes.SET_ACTIVE_ELEMENT_ID_LIST, [])
              // if (slideIndex.value === event.data.index) return
              store.commit(MutationTypes.UPDATE_SLIDE_INDEX, event.data.index)
              if (event.data.type == 'init') {
                window.opener?.postMessage('关闭init定时器', 'https://courseware.brimfar.online')
              }
            }
            if (event.data.type == 'mouse') {
              mousewheelListener(event.data.index)
            }
            if (event.data.type == 'keydown') {
              keydownListener(event.data.index)
            }
            if (event.data.type == '上一页') {
              turnPrevSlide()
            }
            if (event.data.type == '下一页') {
              turnNextSlide()
            }
            if (event.data.type == '第一页') {
              turnSlideToIndex(0)
            }
            if (event.data.type == '最后一页') {
              turnSlideToIndex(slides.value.length - 1)
            }
            if (event.data.type == '自动放映') {
              autoPlay()
            }
            if (event.data.type == '取消自动放映') {
              closeAutoPlay()
            }
            if (event.data.type == '打开画布') {
              writingBoardToolVisible.value = event.data.index
            }
            if (event.data.type == '播放视频') {
              const videoOnly = <HTMLVideoElement>document.getElementById(event.data.index)
              if (videoOnly) {
                videoOnly.play()
              }
            }
            if (event.data.type == '暂停视频') {
              const videoOnly = <HTMLVideoElement>document.getElementById(event.data.index)
              if (videoOnly) {
                videoOnly?.pause()
              }
            }
            if (event.data.type == '视频播放速度') {
              const videoOnly = <HTMLVideoElement>document.getElementById(event.data.index.videoId)
              if (videoOnly) {
                videoOnly.playbackRate = event.data.index.playbackRate
              }
            }
            if (event.data.type == '改变进度条') {
              const videoOnly = <HTMLVideoElement>document.getElementById(event.data.index.videoId)
              if (videoOnly) {
                videoOnly.currentTime = event.data.index.currentTime ? event.data.index.currentTime : 0
              }
            }
            // if (event.data.type == '改变音量') {
            //   const videoOnly = <HTMLVideoElement>document.getElementById(event.data.index.videoId)
            // }

            if (event.data.type == '播放音频') {
              const audioOnly = <HTMLVideoElement>document.getElementsByClassName('audioPPT')[event.data.index]
              if (audioOnly) {
                audioOnly.play()
              }
            }
            if (event.data.type == '暂停音频') {
              const audioOnly = <HTMLVideoElement>document.getElementsByClassName('audioPPT')[event.data.index]
              if (audioOnly) {
                audioOnly.pause()
              }
            }
            if (event.data.type == '改变音频进度条') {
              const audioOnly = <HTMLVideoElement>document.getElementsByClassName('audioPPT')[event.data.index.audioIndex]
              if (audioOnly) {
                audioOnly.currentTime = event.data.index.currentTime
              }
            } 
          })
        }
      })
      // const videoDom = document.getElementsByClassName('vjs-tech')
      // nextTick(() => {
      //   console.log(videoDom);
        
      //   const videoId: any[] = reactive([])
      //   for (let index = 0; index < videoDom.length; index++) {
      //     videoId.push(videoDom[index].id)
      //   }
      //     console.log(videoId);
      //   // 监听video进入全屏    
      //   document.addEventListener('fullscreenchange', (e: any) => {
      //     console.log(videoId);
          
      //     if (!document.fullscreenElement && videoId.includes(e.target.id + '_html5_api')) {
      //       window.addEventListener('keydown', (e: any) => {
      //         if (e.keyCode == 27) {
      //           console.log('点了esc');
              
      //           return
      //         }
      //       })
      //     }
      //   })

      // window.addEventListener('keydown', (e: any) => {
      //       if (e.keyCode == 27) {
      //         console.log('点了esc');
      //         return
      //       }
      //     })
      // })
      
      // document.addEventListener('fullscreenerror', (e: any) => {
      //   console.log(e)
      // })
    })
    
    onUnmounted(() => {
      // document.removeEventListener('keydown', keydownListener)
      
    })

    const contextmenus = (): ContextmenuItem[] => {
      return [
        {
          text: '上一页',
          subText: '↑ ←',
          disable: slideIndex.value <= 0,
          handler: () => turnPrevSlide(),
        },
        {
          text: '下一页',
          subText: '↓ →',
          disable: slideIndex.value >= slides.value.length - 1,
          handler: () => turnNextSlide(),
        },
        {
          text: '第一页',
          disable: slideIndex.value === 0,
          handler: () => turnSlideToIndex(0),
        },
        {
          text: '最后一页',
          disable: slideIndex.value === slides.value.length - 1,
          handler: () => turnSlideToIndex(slides.value.length - 1),
        },
        { divider: true },
        {
          text: '显示页码',
          subText: showPageNumber.value ? '√' : '',
          handler: () => showPageNumber.value = !showPageNumber.value,
        },
        {
          text: '查看所有幻灯片',
          handler: () => slideThumbnailModelVisible.value = true,
        },
        {
          text: '画笔',
          handler: () => writingBoardToolVisible.value = true,
        },
        { divider: true },
        {
          text: autoPlayTimer.value ? '取消自动放映' : '自动放映',
          handler: autoPlayTimer.value ? closeAutoPlay : autoPlay,
        },
        {
          text: '结束放映',
          subText: 'ESC',
          handler: exitScreening,
        },
      ]
    }
    
    provide('slideScale', scale)

    return {
      slides,
      slideIndex,
      currentSlide,
      slideWidth,
      slideHeight,
      scale,
      mousewheelListener,
      animationIndex,
      contextmenus,
      execPrev,
      execNext,
      slideThumbnailModelVisible,
      turnSlideToIndex,
      writingBoardToolVisible,
      showPageNumber,
      preview,
      closeWindow
    }
  },
})
