import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("marker", {
    id: `${_ctx.id}-${_ctx.type}-${_ctx.position}`,
    markerUnits: "userSpaceOnUse",
    orient: "auto",
    markerWidth: _ctx.size * 3,
    markerHeight: _ctx.size * 3,
    refX: _ctx.size * 1.5,
    refY: _ctx.size * 1.5
  }, [
    _createVNode("path", {
      d: _ctx.path,
      fill: _ctx.color,
      transform: `scale(${_ctx.size * 0.3}, ${_ctx.size * 0.3}) rotate(${_ctx.rotate}, 5, 5)`
    }, null, 8, ["d", "fill", "transform"])
  ], 8, ["id", "markerWidth", "markerHeight", "refX", "refY"]))
}