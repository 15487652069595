
import { computed, defineComponent } from 'vue'
import { MutationTypes, useStore } from '@/store'
import { Slide } from '@/types/slides'

export default defineComponent({
  name: 'remark',
  props: {
    height: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore()
    const slides = computed(() => store.state.slides)
    const slideIndex = computed(() => store.state.slideIndex)
    const currentSlide = computed<Slide>(() => store.getters.currentSlide)
    const remark = computed(() => currentSlide.value?.remark || '')

    const handleInput = (e: InputEvent) => {
      const value = (e.target as HTMLTextAreaElement).value
      store.commit(MutationTypes.UPDATE_SLIDE, { remark: value })
    }

    const resize = (e: MouseEvent) => {
      let isMouseDown = true
      const startPageY = e.pageY
      const originHeight = props.height

      document.onmousemove = e => {
        if (!isMouseDown) return

        const currentPageY = e.pageY

        const moveY = currentPageY - startPageY
        let newHeight = -moveY + originHeight

        if (newHeight < 40) newHeight = 40
        if (newHeight > 120) newHeight = 120

        emit('update:height', newHeight)
      }

      document.onmouseup = () => {
        isMouseDown = false
        document.onmousemove = null
        document.onmouseup = null
      }
    }

    return {
      remark,
      handleInput,
      resize,
      slides,
      slideIndex
    }
  },
})
