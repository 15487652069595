
export default {
  name: 'checkbox-button',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
}
