/*
 * @Author: your name
 * @Date: 2021-06-08 10:43:46
 * @LastEditTime: 2021-06-08 11:57:31
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \PPTist\src\views\components\element\audioElement\useFilter.ts
 */
import { computed, Ref } from 'vue'
import { AudioElementFilters } from '@/types/slides'

export default (filters: Ref<AudioElementFilters | undefined>) => {
  const filter = computed(() => {
    if (!filters.value) return ''
    let filter = ''
    for (const key of Object.keys(filters.value)) {
      filter += `${key}(${filters.value[key]}) `
    }
    return filter
  })

  return {
    filter,
  }
}