/*
 * @Author: your name
 * @Date: 2021-05-26 14:42:54
 * @LastEditTime: 2021-07-17 13:17:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \PPTist\src\utils\image.ts
 */
interface ImageSize {
  width: number;
  height: number;
}

import axios from 'axios'

/**
 * 获取图片的原始宽高
 * @param src 图片地址
 */
export const getImageSize = (src: string): Promise<ImageSize> => {
  return new Promise(resolve => {
    const img = document.createElement('img')
    img.src = src
    img.style.opacity = '0'
    document.body.appendChild(img)
    
    img.onload = () => {
      const imgWidth = img.clientWidth
      const imgHeight = img.clientHeight
    
      img.onload = null
      img.onerror = null

      document.body.removeChild(img)

      resolve({ width: imgWidth, height: imgHeight })
    }

    img.onerror = () => {
      img.onload = null
      img.onerror = null
    }
  })
}

/**
 * 读取图片文件的dataURL
 * @param file 图片文件
 */
export const getImageDataURL = (file: File): Promise<string> => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      const token: any = sessionStorage.getItem('token')

      const config = {
        headers: { 'Authorization': token },
      }
      const param = new FormData()
      param.append('file', file)
      axios.post('https://api.brimfar.online/file/upload/', param, config).then(res => {
        if (res.data.status === 200) {
          resolve('https://api.brimfar.online/static' + res.data.data.relativePath)
        } else {
          alert(res.data.message)
        }
      }).catch((e: any)=>{
        if (e.message.indexOf('403') != '-1') {
          alert('身份信息已过期、请重新登录!')
        }
      })
    })
    reader.readAsBinaryString(file)
  })
}