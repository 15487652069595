import { withModifiers as _withModifiers, createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuContent = _resolveComponent("MenuContent")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", {
      class: "mask",
      onContextmenu: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.removeContextmenu()), ["prevent"])),
      onMousedown: _cache[2] || (_cache[2] = ($event: any) => (_ctx.removeContextmenu()))
    }, null, 32),
    _createVNode("div", {
      class: "contextmenu",
      style: {
      left: _ctx.style.left + 'px',
      top: _ctx.style.top + 'px',
    },
      onContextmenu: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
    }, [
      _createVNode(_component_MenuContent, {
        menus: _ctx.menus,
        handleClickMenuItem: _ctx.handleClickMenuItem
      }, null, 8, ["menus", "handleClickMenuItem"])
    ], 36)
  ], 64))
}