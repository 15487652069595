
import { computed, defineComponent, PropType, watch, ref, nextTick, getCurrentInstance } from 'vue'
import { MutationTypes, useStore } from '@/store'
import { AudioElementClip, PPTAudioElement } from '@/types/slides'
import { AudioClipedEmitData } from '@/types/edit'
import { ContextmenuItem } from '@/components/Contextmenu/types'
import useElementShadow from '@/views/components/element/hooks/useElementShadow'
import useElementFlip from '@/views/components/element/hooks/useElementFlip'
import useClipAudio from './useClipAudio'
import useFilter from './useFilter'

import AudioOutline from './AudioOutline/index.vue'
// import ImageClipHandler from './ImageClipHandler.vue'

export default defineComponent({
  components: {
    AudioOutline,
    // ImageClipHandler,
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTAudioElement>,
      required: true,
    },
    selectElement: {
      type: Function as PropType<(e: MouseEvent, element: PPTAudioElement, canMove?: boolean) => void>,
      required: true,
    },
    contextmenus: {
      type: Function as PropType<() => ContextmenuItem[]>,
    },
  },
  setup(props) {
    const store = useStore()
    const clipingImageElementId = computed(() => store.state.clipingImageElementId)
    const isCliping = computed(() => clipingImageElementId.value === props.elementInfo.id)

    const shadow = computed(() => props.elementInfo.shadow)
    const { shadowStyle } = useElementShadow(shadow)

    const flip = computed(() => props.elementInfo.flip)
    const { flipStyle } = useElementFlip(flip)

    const clip = computed(() => props.elementInfo.clip)
    const { clipShape, imgPosition } = useClipAudio(clip)

    const filters = computed(() => props.elementInfo.filters)
    const { filter } = useFilter(filters)

    const handleSelectElement = (e: MouseEvent) => {
      if (props.elementInfo.lock) return
      e.stopPropagation()
      props.selectElement(e, props.elementInfo)
    }

    const handleClip = (data: AudioClipedEmitData) => {
      store.commit(MutationTypes.SET_CLIPING_IMAGE_ELEMENT_ID, '')

      
      if (!data) return

      const { range, position } = data
      const originClip: AudioElementClip = props.elementInfo.clip || { shape: 'rect', range: [[0, 0], [100, 100]] }
      
      const _props = {
        clip: { ...originClip, range },
        left: props.elementInfo.left + position.left,
        top: props.elementInfo.top + position.top,
        width: props.elementInfo.width + position.width,
        height: props.elementInfo.height + position.height,
      }
      store.commit(MutationTypes.UPDATE_ELEMENT, { id: props.elementInfo.id, props: _props })
    }
    
    const mitter = getCurrentInstance()?.appContext.config.globalProperties.mitter
    const audioDom = document.getElementsByClassName('audioPPT')

    nextTick(() => {
      
      for (let index = 0; index < audioDom.length; index++) {
        audioDom[index].addEventListener('play', () => {
          mitter.emit('onlyPlayAudio', index)
        })
      }
      
    })
    return {
      isCliping,
      handleClip,
      clipingImageElementId,
      shadowStyle,
      handleSelectElement,
      clipShape,
      imgPosition,
      filter,
      flipStyle,
    }
  },
})
