
import axios from 'axios'
import { computed, defineComponent, onMounted, getCurrentInstance, ref } from 'vue'
import { MutationTypes, ActionTypes, useStore } from '@/store'
import useScreening from '@/hooks/useScreening'
import { isFullscreen } from '@/utils/fullscreen'

import Editor from './views/Editor/index.vue'
import Screen from './views/Screen/index.vue'

import notesView from './views/notesView/Editor/index.vue'

import audience from './views/audience/Screen/index.vue'

export default defineComponent({
  name: 'app',
  components: {
    Editor,
    Screen,
    notesView,
    audience
  },
  setup() {
    const store = useStore()
    
    const { enterScreening } = useScreening()
    const showIconFullScreenOne = ref(true)
    const openEnterScreening = () => {
      showIconFullScreenOne.value = false
      enterScreening()
    }
    window.addEventListener('resize', () => {
      if(!isFullscreen()) {
        showIconFullScreenOne.value = true
      }
    })
    // 获取参数
    const getQueryVariable: any = (variable: any) => {
      const query = window.location.search.substring(1)
      const vars = query.split('&')
      for (let i = 0; i < vars.length; i ++) {
        const pair = vars[i].split('=')
        if (pair[0] === variable) {
          return pair[1]
        }
      }
      return (false)
    }
    

    const baseUrl = getCurrentInstance()?.appContext.config.globalProperties.baseUrl
    const pptID = getQueryVariable('id')
    let token: string = getQueryVariable('token')
    if (token) {
      token = token.replace('%20', ' ')
    }
    const message = getCurrentInstance()?.appContext.config.globalProperties.message
    if (pptID) {
    // 预览
      axios.get(baseUrl + '/courseResource/detail', { params: {id: pptID}, headers: {'Authorization': token }}).then( res => {
        if(res.data.status === 200) {
          res.data.data && res.data.data.pptContent && store.commit(MutationTypes.SET_SLIDES, JSON.parse(res.data.data.pptContent))
        }else {
          message.error(res.data.message)
        }
      }).catch((e: any)=>{
        if (e.message.indexOf('403') != '-1') {
          alert('身份信息已过期、请重新登录!')
        }
      })
    }

    const preview = getQueryVariable('preview')
    
    const screening = computed(() => store.state.screening)

    if (process.env.NODE_ENV === 'production') {
      window.onbeforeunload = () => false
    }
    
    const remarkView: any = ref(true)
    const mitter = getCurrentInstance()?.appContext.config.globalProperties.mitter
    mitter.on('switchPage', (e: any) => {
      remarkView.value = false
    })
    mitter.on('closeShow', (e: any) => {
      remarkView.value = true
    })
    
    const AudienceCondition = ref(false)
    // 观众窗口
    if (window.location.href.slice(window.location.href.lastIndexOf('/') + 1, window.location.href.length) == 'Audience') {
      sessionStorage.setItem('isSlideshow', '')
      remarkView.value = false
      AudienceCondition.value = true
      axios.get(baseUrl + '/courseResource/detail', { params: {id: sessionStorage.getItem('id')}, headers: {'Authorization':  sessionStorage.getItem('token')}}).then( res => {
        if(res.data.status === 200) {
          res.data.data && res.data.data.pptTheme && store.commit(MutationTypes.SET_THEME, JSON.parse(res.data.data.pptTheme))
          res.data.data && res.data.data.pptContent && store.commit(MutationTypes.SET_SLIDES, JSON.parse(res.data.data.pptContent))
        }else{
          message.error(res.data.message)
        }
      }).catch((e: any)=>{
        if (e.message.indexOf('403') != '-1') {
          alert('身份信息已过期、请重新登录!')
        }
      })
    }
    
    onMounted(() => {
      store.commit(MutationTypes.SET_AVAILABLE_FONTS)
      store.dispatch(ActionTypes.INIT_SNAPSHOT_DATABASE)
    })
    return {
      screening,
      preview,
      remarkView,
      AudienceCondition,
      openEnterScreening,
      showIconFullScreenOne
    }
  },
})
