/*
 * @Author: your name
 * @Date: 2021-05-26 14:42:54
 * @LastEditTime: 2021-07-16 16:17:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \PPTist\src\hooks\useScreening.ts
 */
import { MutationTypes, useStore } from '@/store'
import { enterFullscreen, exitFullscreen, isFullscreen } from '@/utils/fullscreen'
import { getCurrentInstance } from 'vue'

export default () => {
  const store = useStore()
  // 进入放映状态（从当前页开始）
  const enterScreening = () => {
    enterFullscreen()
    store.commit(MutationTypes.SET_SCREENING, true)
  }

  // 进入放映状态（从第一页开始）
  const enterScreeningFromStart = () => {
    store.commit(MutationTypes.UPDATE_SLIDE_INDEX, 0)
    enterScreening()
  }

  // 退出放映状态
  const exitScreening = () => {
    store.commit(MutationTypes.SET_SCREENING, false)
    if (isFullscreen()) exitFullscreen()
  }

  return {
    enterScreening,
    enterScreeningFromStart,
    exitScreening,
  }
}