
import { defineComponent, computed, ref, getCurrentInstance, onMounted } from 'vue'
import { MutationTypes, useStore } from '@/store'
// 修改
import { getAudioDataURL } from '@/utils/audio'
import { getVideoDataURL } from '@/utils/video'
import { getImageDataURL } from '@/utils/image'
import { ShapePoolItem } from '@/configs/shapes'
import { LinePoolItem } from '@/configs/lines'
import useScaleCanvas from '@/hooks/useScaleCanvas'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'
import useCreateElement from '@/hooks/useCreateElement'

import ShapePool from './ShapePool.vue'
import LinePool from './LinePool.vue'
import ChartPool from './ChartPool.vue'
import TableGenerator from './TableGenerator.vue'

export default defineComponent({
  name: 'canvas-tool',
  components: {
    ShapePool,
    LinePool,
    ChartPool,
    TableGenerator,
  },
  setup() {
    const store = useStore()
    const canvasScale = computed(() => store.state.canvasScale)
    const canUndo = computed(() => store.getters.canUndo)
    const canRedo = computed(() => store.getters.canRedo)

    const canvasScalePercentage = computed(() => parseInt(canvasScale.value * 100 + '') + '%')

    const { scaleCanvas, setCanvasPercentage } = useScaleCanvas()
    const { redo, undo } = useHistorySnapshot()

    const { createAudioElement, createVideoElement, createImageElement, createChartElement, createTableElement } = useCreateElement()
    // 图片
    const spinning = ref(false)
    const message = getCurrentInstance()?.appContext.config.globalProperties.message
    const insertImageElement = (files: File[]) => {
      spinning.value = true
      const imageFile = files[0] 
      if (!imageFile) return
      getImageDataURL(imageFile).then(dataURL => {
        spinning.value = false
        message.success('图片上传成功!')
        createImageElement(dataURL)
      })
    }
    // 视频
    const insertVideoElement = (files: File[]) => {
      spinning.value = true
      const VideoFile = files[0]

      if (!VideoFile) return
      getVideoDataURL(VideoFile).then(dataURL => {
        spinning.value = false
        message.success('视频上传成功!')
        createVideoElement(dataURL)
      })
    }
    // 音频
    const insertAudioElement = (files: File[]) => {
      spinning.value = true
      const MusicFile = files[0]

      if (!MusicFile) return
      getAudioDataURL(MusicFile).then(dataURL => {
        spinning.value = false
        message.success('音频上传成功!')
        createAudioElement(dataURL)
      })
    }

    const shapePoolVisible = ref(false)
    const linePoolVisible = ref(false)
    const chartPoolVisible = ref(false)
    const tableGeneratorVisible = ref(false)

    // 绘制文字范围
    const drawText = () => {
      store.commit(MutationTypes.SET_CREATING_ELEMENT, {
        type: 'text',
        data: '请输入内容',
      })
    }

    // 绘制形状范围
    const drawShape = (shape: ShapePoolItem) => {
      store.commit(MutationTypes.SET_CREATING_ELEMENT, {
        type: 'shape',
        data: shape,
      })
      shapePoolVisible.value = false
    }

    // 绘制线条路径
    const drawLine = (line: LinePoolItem) => {
      store.commit(MutationTypes.SET_CREATING_ELEMENT, {
        type: 'line',
        data: line,
      })
      linePoolVisible.value = false
    }
    
    const mitter = getCurrentInstance()?.appContext.config.globalProperties.mitter
    onMounted(() => {
      mitter.on('openSpin', () => {
        spinning.value = true
      })
      mitter.on('closeSpin', () => {
        spinning.value = false
      })
    })

    return {
      scaleCanvas,
      setCanvasPercentage,
      canvasScalePercentage,
      canUndo,
      canRedo,
      redo,
      undo,
      insertImageElement,
      insertVideoElement,
      insertAudioElement,
      shapePoolVisible,
      linePoolVisible,
      chartPoolVisible,
      tableGeneratorVisible,
      drawText,
      drawShape,
      drawLine,
      createChartElement,
      createTableElement,
      spinning
    }
  },
})
