
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'file-input',
  props: {
    accept: {
      type: String,
      default: 'video/*',
    },
  },
  setup(props, { emit }) {
    const inputRef = ref<HTMLInputElement>()

    const handleClick = () => {
      if (!inputRef.value) return
      inputRef.value.value = ''
      inputRef.value.click()
    }

    const handleChange = (e: InputEvent) => {
      const files: any = (e.target as HTMLInputElement).files
      if (files) emit('change', files)
    }
    
    
    

    return {
      handleClick,
      handleChange,
      inputRef,
    }
  },
})
