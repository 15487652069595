
import { PropType } from 'vue'
import { OperateResizeHandler } from '@/types/edit'

export default {
  name: 'resize-handler',
  props: {
    type: {
      type: String as PropType<OperateResizeHandler>,
      default: '',
    },
  },
}
