import { SlideBackground } from './../types/slides';
import { computed } from 'vue'
import { MutationTypes, useStore } from '@/store'
import { createRandomCode } from '@/utils/common'
import { getImageSize } from '@/utils/image'
import { getVideoSize } from '@/utils/video'
import { getAudioSize } from '@/utils/audio'
import { VIEWPORT_SIZE } from '@/configs/canvas'
import { PPTAnimation, PPTLineElement, ChartType, PPTElement, TableCell } from '@/types/slides'
import { ShapePoolItem } from '@/configs/shapes'
import { LinePoolItem } from '@/configs/lines'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

interface CommonElementPosition {
  top: number;
  left: number;
  width: number;
  height: number;
}

interface LineElementPosition {
  top: number;
  left: number;
  start: [number, number];
  end: [number, number];
}

export default () => {
  const store = useStore()
  const canvasScale = computed(() => store.state.canvasScale)
  const themeColor = computed(() => store.state.theme.themeColor)
  const fontColor = computed(() => store.state.theme.fontColor)
  const viewportRatio = computed(() => store.state.viewportRatio)

  const { addHistorySnapshot } = useHistorySnapshot()

  // 创建（插入）一个元素并将其设置为被选中元素
  const createElement = (element: PPTElement) => {
    // 新增
    store.commit(MutationTypes.ADD_ELEMENT, element)
    // 设置为选中
    element.type == 'video' && !element.videoSource && store.commit(MutationTypes.SET_ACTIVE_ELEMENT_ID_LIST, [element.id])
    element.type != 'video' && store.commit(MutationTypes.SET_ACTIVE_ELEMENT_ID_LIST, [element.id])
    addHistorySnapshot()
  }

  /**
   * 创建音频元素
   * @param src 音频地址
   */
   const createAudioElement = (src: string) => {
    
    getAudioSize(src).then(({ width, height }) => {
      
      const scale = height / width
      
      // VIEWPORT_SIZE
      if (scale < viewportRatio.value && width > VIEWPORT_SIZE) {
        width = VIEWPORT_SIZE
        height = width * scale
      }
      else if (height > VIEWPORT_SIZE * viewportRatio.value) {
        height = VIEWPORT_SIZE * viewportRatio.value
        width = height / scale
      }
      width = 320
      height = 60
      createElement({
        type: 'audio',
        id: createRandomCode(),
        src,
        width,
        height,
        left: (VIEWPORT_SIZE - width) / 2,
        top: (VIEWPORT_SIZE * viewportRatio.value - height) / 2,
        fixedRatio: true,
        rotate: 0,
      })
    })
  }
  /**
   * 创建视频元素
   * @param src 视频地址
   */
  const createVideoElement = (src: string) => {
    
    getVideoSize(src).then(({ width, height }) => {
      
      const scale = height / width
      
      // VIEWPORT_SIZE
      if (scale < viewportRatio.value && width > VIEWPORT_SIZE) {
        width = VIEWPORT_SIZE
        height = width * scale
      }
      else if (height > VIEWPORT_SIZE * viewportRatio.value) {
        height = VIEWPORT_SIZE * viewportRatio.value
        width = height / scale
      }
      width = 420
      height = width * scale

      createElement({
        type: 'video',
        id: createRandomCode(),
        src,
        width,
        height,
        left: (VIEWPORT_SIZE - width) / 2,
        top: (VIEWPORT_SIZE * viewportRatio.value - height) / 2,
        fixedRatio: true,
        rotate: 0,
        marker: []
      })
    })
  }
  // 资源库添加视频
  const createResourceVideoElement = (src: string, actionPoint: any[], actionPointKnowledges: any[]) => {
    
    getVideoSize(src).then(({ width, height }) => {
      
      // const scale = height / width
      
      // VIEWPORT_SIZE
      // if (scale < viewportRatio.value && width > VIEWPORT_SIZE) {
      //   width = VIEWPORT_SIZE
      //   height = width * scale
      // }
      // else if (height > VIEWPORT_SIZE * viewportRatio.value) {
      //   height = VIEWPORT_SIZE * viewportRatio.value
      //   width = height / scale
      // }
     
      width = actionPoint.length > 0 ? document.getElementsByClassName('viewport-wrapper')[0].clientWidth * 0.75 / canvasScale.value : document.getElementsByClassName('viewport-wrapper')[0].clientWidth / canvasScale.value
      height = document.getElementsByClassName('viewport-wrapper')[0].clientHeight / canvasScale.value
      // console.log(width, height);
      
      createElement({
        type: 'video',
        id: createRandomCode(),
        src,
        width,
        height,
        // left: (VIEWPORT_SIZE - width) / 2,
        left: 0,
        // top: (VIEWPORT_SIZE * viewportRatio.value - height) / 2,
        top: 0,
        fixedRatio: true,
        rotate: 0,
        marker: actionPoint,
        videoSource: true,
        actionPointKnowledges
      })
    })
  }
  /**
   * 创建图片元素
   * @param src 图片地址
   */
  const createImageElement = (src: string) => {
    getImageSize(src).then(({ width, height }) => {
      const scale = height / width
      if (scale < viewportRatio.value && width > VIEWPORT_SIZE) {
        width = VIEWPORT_SIZE
        height = width * scale
      }
      else if (height > VIEWPORT_SIZE * viewportRatio.value) {
        height = VIEWPORT_SIZE * viewportRatio.value
        width = height / scale
      }

      createElement({
        type: 'image',
        id: createRandomCode(),
        src,
        width,
        height,
        left: (VIEWPORT_SIZE - width) / 2,
        top: (VIEWPORT_SIZE * viewportRatio.value - height) / 2,
        fixedRatio: true,
        rotate: 0,
      })
    })
  }
  
  /**
   * 创建图表元素
   * @param chartType 图表类型
   */
  const createChartElement = (chartType: ChartType) => {
    createElement({
      type: 'chart',
      id: createRandomCode(),
      chartType,
      left: 300,
      top: 81.25,
      width: 400,
      height: 400,
      themeColor: themeColor.value,
      gridColor: fontColor.value,
      data: {
        labels: ['类别1', '类别2', '类别3', '类别4', '类别5'],
        series: [
          [12, 19, 5, 2, 18],
        ],
      },
    })
  }
  
  /**
   * 创建表格元素
   * @param row 行数
   * @param col 列数
   */
  const createTableElement = (row: number, col: number) => {
    const rowCells: TableCell[] = new Array(col).fill({ id: createRandomCode(), colspan: 1, rowspan: 1, text: '' })
    const data: TableCell[][] = new Array(row).fill(rowCells)

    const DEFAULT_CELL_WIDTH = 100
    const DEFAULT_CELL_HEIGHT = 36

    const colWidths: number[] = new Array(col).fill(1 / col)

    const width = col * DEFAULT_CELL_WIDTH
    const height = row * DEFAULT_CELL_HEIGHT

    createElement({
      type: 'table',
      id: createRandomCode(),
      width,
      height,
      colWidths,
      data,
      left: (VIEWPORT_SIZE - width) / 2,
      top: (VIEWPORT_SIZE * viewportRatio.value - height) / 2,
      outline: {
        width: 2,
        style: 'solid',
        color: '#eeece1',
      },
      theme: {
        color: themeColor.value,
        rowHeader: true,
        rowFooter: false,
        colHeader: false,
        colFooter: false,
      },
    })
  }
  const currentSlideAnimations = computed<PPTAnimation[] | null>(() => store.getters.currentSlideAnimations)
  
  /**
   * 创建文本元素
   * @param position 位置大小信息
   * @param content 文本内容
   */

  // 添加元素入场动画
  const addAnimation = (id: string, type: string, isEnter: Boolean) => {
    const animations: PPTAnimation[] = currentSlideAnimations.value ? JSON.parse(JSON.stringify(currentSlideAnimations.value)) : []
    animations.push({
      elId: id,
      isEnter,
      type,
      duration: 1000,
    })
    store.commit(MutationTypes.UPDATE_SLIDE, { animations })
  }
  const createTextElement = (position: CommonElementPosition, textType: String, content = '请输入内容') => {
    let { left, top, width, height } = position
    if (textType === 'question') {
      // width = 600
      // height = 200
      
      let question = JSON.parse(content)
      let id=createRandomCode()
      content='题目：\n'+question.question
      createElement({
        type: 'text',
        id,
        left, 
        top, 
        width, 
        height,
        content,
        fill: 'rgb(255, 255, 255)',
        rotate: 0,
      })
      addAnimation(id, 'fadeInUp', true)
      id=createRandomCode()
      content='答案：\n'+question.answer
      createElement({
        type: 'text',
        id,
        left, 
        top, 
        width, 
        height,
        content,
        fill: 'rgb(255, 255, 255)',
        rotate: 0,
      })
      addAnimation(id, 'fadeInUp', true)
      id=createRandomCode()
      content='解析：\n'+question.resolution
      createElement({
        type: 'text',
        id,
        left, 
        top, 
        width, 
        height,
        content,
        fill: 'rgb(255, 255, 255)',
        rotate: 0,
      })
      addAnimation(id,'fadeInUp', true)
    } else {
      createElement({
        type: 'text',
        id: createRandomCode(),
        left, 
        top, 
        width, 
        height,
        content,
        fontColor: fontColor.value,
        fill: 'rgb(255, 255, 255, 0)',
        rotate: 0,
      })
    }
  }
  
  /**
   * 创建形状元素
   * @param position 位置大小信息
   * @param data 形状路径信息
   */
  const createShapeElement = (position: CommonElementPosition, data: ShapePoolItem) => {
    const { left, top, width, height } = position
    createElement({
      type: 'shape',
      id: createRandomCode(),
      left, 
      top, 
      width, 
      height,
      viewBox: data.viewBox,
      path: data.path,
      fill: themeColor.value,
      fixedRatio: false,
      rotate: 0,
    })
  }
  
  /**
   * 创建线条元素
   * @param position 位置大小信息
   * @param data 线条的路径和样式
   */
  const createLineElement = (position: LineElementPosition, data: LinePoolItem) => {
    const { left, top, start, end } = position

    const newElement: PPTLineElement = {
      type: 'line',
      id: createRandomCode(),
      left, 
      top, 
      start,
      end,
      points: data.points,
      color: themeColor.value,
      style: data.style,
      width: 2,
    }
    if (data.isBroken) newElement.broken = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2]
    if (data.isCurve) newElement.curve = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2]
    createElement(newElement)
  }

  return {
    createAudioElement,
    createVideoElement,
    createResourceVideoElement,
    createImageElement,
    createChartElement,
    createTableElement,
    createTextElement,
    createShapeElement,
    createLineElement,
  }
}