/*
 * @Author: your name
 * @Date: 2021-05-26 16:44:33
 * @LastEditTime: 2021-07-17 13:17:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \PPTist\src\utils\video.ts
 */

interface VideoSize {
  width: number;
  height: number;
}
  
/**
 * 获取视频的原始宽高
 * @param src 图片地址
 */
export const getVideoSize = (src: string): Promise<VideoSize> => {
  return new Promise(resolve => {
    const video = document.createElement('video')
    video.src = src
    video.style.opacity = '0'
    document.body.appendChild(video)
    
    video.onloadstart = () => {
      const videoWidth = video.clientWidth
      const videoHeight = video.clientHeight
      video.onloadstart = null
      video.onerror = null

      document.body.removeChild(video)

      resolve({ width: videoWidth, height: videoHeight })
    }

    video.onerror = () => {
      video.onload = null
      video.onerror = null
    }
  })
}

import axios from 'axios'

/**
 * 读取视频文件的dataURL
 * @param file 视频文件
 */
export const getVideoDataURL = (file: File): Promise<string> => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      const token: any = sessionStorage.getItem('token')

      const config = {
        headers: { 'Authorization': token }
      }
      const param = new FormData()
      param.append('file', file)
      axios.post('https://api.brimfar.online/file/upload/', param, config).then(res => {
        if (res.data.status === 200) {
          resolve('https://api.brimfar.online/static' + res.data.data.relativePath)
        } else {
          alert(res.data.message)
        }
      }).catch((e: any)=>{
        if (e.message.indexOf('403') != '-1') {
          alert('身份信息已过期、请重新登录!')
        }
      })
    })
    reader.readAsBinaryString(file)
  })
}