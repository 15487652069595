import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: "screen-element",
    id: `screen-element-${_ctx.elementInfo.id}`,
    style: {
      zIndex: _ctx.elementIndex,
      color: _ctx.theme.fontColor,
      fontFamily: _ctx.theme.fontName,
      visibility: _ctx.needWaitAnimation ? 'hidden' : 'visible',
    }
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentElementComponent), { elementInfo: _ctx.elementInfo }, null, 8, ["elementInfo"]))
  ], 12, ["id"]))
}