
import { computed, defineComponent, PropType } from 'vue'
import { useStore } from '@/store'

import { PPTLineElement } from '@/types/slides'
import { OperateLineHandler, OperateLineHandlers } from '@/types/edit'

import ResizeHandler from './ResizeHandler.vue'

export default defineComponent({
  name: 'text-element-operate',
  inheritAttrs: false,
  components: {
    ResizeHandler,
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTLineElement>,
      required: true,
    },
    isActiveGroupElement: {
      type: Boolean,
      required: true,
    },
    isMultiSelect: {
      type: Boolean,
      required: true,
    },
    dragLineElement: {
      type: Function as PropType<(e: MouseEvent, element: PPTLineElement, command: OperateLineHandler) => void>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const canvasScale = computed(() => store.state.canvasScale)

    const resizeHandlers = computed(() => {
      const handlers = [
        {
          handler: OperateLineHandlers.START,
          style: {
            left: props.elementInfo.start[0] * canvasScale.value + 'px',
            top: props.elementInfo.start[1] * canvasScale.value + 'px',
          }
        },
        {
          handler: OperateLineHandlers.END,
          style: {
            left: props.elementInfo.end[0] * canvasScale.value + 'px',
            top: props.elementInfo.end[1] * canvasScale.value + 'px',
          }
        },
      ]

      if (props.elementInfo.curve || props.elementInfo.broken) {
        const midHandler = (props.elementInfo.curve || props.elementInfo.broken) as [number, number]

        handlers.push({
          handler: OperateLineHandlers.MID,
          style: {
            left: midHandler[0] * canvasScale.value + 'px',
            top: midHandler[1] * canvasScale.value + 'px',
          }
        })
      }
      return handlers
    })

    return {
      resizeHandlers,
    }
  },
})
