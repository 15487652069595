import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: "base-element",
    style: {
      zIndex: _ctx.elementIndex
    }
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentElementComponent), {
      elementInfo: _ctx.elementInfo,
      target: "thumbnail"
    }, null, 8, ["elementInfo"]))
  ], 4))
}