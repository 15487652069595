import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "image-outline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageRectOutline = _resolveComponent("ImageRectOutline")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_ImageRectOutline, {
      width: _ctx.elementInfo.width,
      height: _ctx.elementInfo.height,
      radius: _ctx.clipShape.radius,
      outline: _ctx.elementInfo.outline
    }, null, 8, ["width", "height", "radius", "outline"])
  ]))
}