
import { computed, defineComponent, onMounted, onUnmounted, provide, ref, getCurrentInstance, watch, nextTick, reactive } from 'vue'
import throttle from 'lodash/throttle'
import { MutationTypes, useStore } from '@/store'
import { Slide } from '@/types/slides'
import { VIEWPORT_SIZE } from '@/configs/canvas'
import { KEYS } from '@/configs/hotkey'
import { ContextmenuItem } from '@/components/Contextmenu/types'
import { isFullscreen } from '@/utils/fullscreen'
import useScreening from '@/hooks/useScreening'

import { message } from 'ant-design-vue'

import ScreenSlide from './ScreenSlide.vue'
import SlideThumbnails from './SlideThumbnails.vue'
import WritingBoardTool from './WritingBoardTool.vue'

export default defineComponent({
  name: 'screen',
  components: {
    ScreenSlide,
    SlideThumbnails,
    WritingBoardTool,
  },
  setup() {
    const store = useStore()
    const slides = computed(() => store.state.slides)
    const slideIndex = computed(() => store.state.slideIndex)
    const viewportRatio = computed(() => store.state.viewportRatio)
    const currentSlide = computed<Slide>(() => store.getters.currentSlide)

    const slideWidth = ref(0)
    const slideHeight = ref(0)
    
    const scale = computed(() => slideWidth.value / VIEWPORT_SIZE)

    const showPageNumber = ref(false)

    const slideThumbnailModelVisible = ref(false)

    const writingBoardToolVisible = ref(false)

    const getQueryVariable: any = (variable: any) => {
      const query = window.location.search.substring(1)
      const vars = query.split('&')
      for (let i = 0; i < vars.length; i ++) {
        const pair = vars[i].split('=')
        if (pair[0] === variable) {
          return pair[1]
        }
      }
      return (false)
    }

    const preview = getQueryVariable('preview')

    // 计算和更新幻灯片内容的尺寸（按比例自适应屏幕）
    const setSlideContentSize = () => {
      const winWidth = document.getElementsByClassName('slide-list')[0].clientWidth
      const winHeight = document.getElementsByClassName('slide-list')[0].clientHeight
      let width, height

      if (winHeight / winWidth === viewportRatio.value) {
        width = winWidth
        height = winHeight
      }
      else if (winHeight / winWidth > viewportRatio.value) {
        width = winWidth
        height = winWidth * viewportRatio.value
      }
      else {
        width = winHeight / viewportRatio.value
        height = winHeight
      }
      slideWidth.value = width
      slideHeight.value = height
    }

    // 窗口尺寸变化监听：窗口发生变化时更新幻灯片的大小
    // 如果退出了全屏，需要返回到编辑模式
    const { exitScreening } = useScreening()

    const windowResizeListener = () => {
      setSlideContentSize()
      if (!isFullscreen()) {
        exitScreening()
        sessionStorage.setItem('isSlideshow', '不允许刷新')
      }
    }
    const audienceWindow: any = ref('')
    const showAudience = Boolean(sessionStorage.getItem('id'))
    const mitter = getCurrentInstance()?.appContext.config.globalProperties.mitter
    const closeShow = () => {
      mitter.emit('closeShow')
      audienceWindow.value && audienceWindow.value.close()
    }
    // const strWindowFeatures = "width=1000,height=500,menubar=no,location=no,resizable=yes,scrollbars=true,status=no,depended=yes,hotkeys=yes"

    // 当前页的元素动画列表和当前执行到的位置
    const animations = computed(() => currentSlide.value.animations || [])
    const animationIndex = ref(0)
    const videoDom = document.getElementsByClassName('vjs-tech')
    const audioDom = document.getElementsByClassName('audioPPT')
    let initVideoCurrentTime = null
    let initAudioCurrentTime: any = ref('')
    let intervalTime: any = null
    const openAudienceWindow = () => {
      if(audienceWindow.value && !audienceWindow.value.closed) {
        audienceWindow.value.focus()
        return
      }
      audienceWindow.value = window.open('Audience', "_blank", "width=1000,height=500,resizable=no,scrollbars=true,depended=yes")
      intervalTime = setInterval(() => {
        audienceWindow.value.postMessage({
              type: 'init',
              index: slideIndex.value
            }, 'https://courseware.brimfar.online')
      }, 100)
      animationIndex.value = 0
      mitter.emit('getChildWindow', audienceWindow.value)
      mitter.emit('resetKnowledgePoints')
      nextTick(() => {
        for (let index = 0; index < videoDom.length; index++) {
          initVideoCurrentTime = <HTMLVideoElement>document.getElementById(videoDom[index].id)
          if (initVideoCurrentTime) {
            initVideoCurrentTime.pause()
            initVideoCurrentTime.currentTime = 0
            initVideoCurrentTime.playbackRate = 1
          }
        }
        
        for (let i = 0; i < audioDom.length; i++) {
          initAudioCurrentTime = <HTMLAudioElement>audioDom[i]
          if (initAudioCurrentTime) {
            initAudioCurrentTime.currentTime = 0
            initAudioCurrentTime.pause()
          }
        }
      })
    }
    const postMsg = (type: any, data: any) => {
      audienceWindow.value && audienceWindow.value.postMessage({
              type: type,
              index: data
            }, 'https://courseware.brimfar.online')
    }
    watch(writingBoardToolVisible, 
    (newVal, oldVal) => {
      audienceWindow.value && !audienceWindow.value.closed && postMsg('打开画布', newVal)
      if (newVal) {
        setTimeout(() => {
          mitter.emit('getChildW', audienceWindow.value)
        }, 0)
      }
    })
    const changeWriting = (e: any) => {
      postMsg('画笔或橡皮', e)
    }
    const changeWritingColor = (e: any) => {
      postMsg('画笔颜色', e)
    }

    const videoPlay = (elementList: any[]) => {
      let videoMarker: any[] = []
      videoMarker = []
      for (let index = 0; index < elementList.length; index++) {
        for (let i = 0; i < elementList[index].elements.length; i++) {
          if (elementList[index].elements[i].type == 'video') {
            videoMarker.push(elementList[index].elements[i].marker)
          }
        }
      }
      
      nextTick(() => { 
        if (elementList.length == 0 ) {
          return false
        }
        const Dom = document.getElementsByClassName('vjs-volume-panel')
        const fatherDom = document.getElementsByClassName('vjs-control-bar')
        
        for (let i = 0; i < fatherDom.length; i++) {
          if ((fatherDom[i] as HTMLElement)?.children.length == 19) {
            continue
          }
          const frag = document.createDocumentFragment()
          const cycleDiv = document.createElement('div')
          const coherentDiv = document.createElement('div')
          cycleDiv.innerHTML = `<div class="cycle" style="display: flex; justify-content:center; align-items: center; width: 2em; height: 100%; text-align: center; font-size: 18px; cursor: pointer;">
                                  <span title="播放一次" class="playOne i-icon i-icon-play-once" style="display: block" data-v-1e203a46=""
                                  onclick="javascript: 
                                    document.getElementsByClassName('playOne')[${i}].style.display = 'none'; 
                                    document.getElementsByClassName('playN')[${i}].style.display = 'block';
                                  ">
                                    <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none">
                                      <path d="M43.8233 25.2305C43.7019 25.9889 43.5195 26.727 43.2814 27.4395C42.763 28.9914 41.9801 30.4222 40.9863 31.6785C38.4222 34.9201 34.454 37 30 37H16C9.39697 37 4 31.6785 4 25C4 18.3502 9.39624 13 16 13L44 13" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path>
                                      <path d="M38 7L44 13L38 19" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M24 19V31" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M24 19L21 22L19.5 23.5" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                  </span>
                                  <span title="循环播放" class="playN i-icon i-icon-play-cycle" style="display: none" data-v-1e203a46="" 
                                  onclick="javascript: document.getElementsByClassName('playN')[${i}].style.display = 'none'; document.getElementsByClassName('playOne')[${i}].style.display = 'block'">
                                    <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none">
                                      <path d="M4 25C4 18.3502 9.39624 13 16 13L44 13" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M38 7L44 13L38 19" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M44 23C44 29.6498 38.6038 35 32 35H4" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path>
                                      <path d="M10 41L4 35L10 29" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                  </span>
                                </div>`
          coherentDiv.innerHTML = `<div style="display: flex; justify-content:center; align-items: center; width: 2em; height: 100%; text-align: center; font-size: 18px; cursor: pointer;" >
                                    <span title="连贯播放" class="coherent i-icon i-icon-switch-one" style="display: block"  data-v-1e203a46=""
                                    onclick="javascript: 
                                      document.getElementsByClassName('coherent')[${i}].style.display = 'none'; 
                                      document.getElementsByClassName('noCoherent')[${i}].style.display = 'block';
                                    ">
                                      <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none">
                                        <circle cx="24" cy="24" r="19" fill="none" stroke="currentColor" stroke-width="4"></circle>
                                        <rect x="36.0063" y="19.3335" width="10.5189" height="24.0125" rx="5.25944" transform="rotate(90 36.0063 19.3335)" fill="none" stroke="currentColor" stroke-width="4"></rect><rect x="36.0063" y="29.8524" width="10" height="10" rx="5" transform="rotate(-180 36.0063 29.8524)" stroke="currentColor" stroke-width="4"></rect>
                                      </svg>
                                    </span>
                                    <span title="断点播放" class="noCoherent i-icon i-icon-switch-button" style="display: none" data-v-1e203a46=""
                                    onclick="javascript: 
                                      document.getElementsByClassName('noCoherent')[${i}].style.display = 'none'; 
                                      document.getElementsByClassName('coherent')[${i}].style.display = 'block';
                                    ">
                                      <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none">
                                        <rect x="4" y="4" width="40" height="16" rx="8" fill="none" stroke="currentColor" stroke-width="4" stroke-linejoin="round"></rect><rect x="4" y="28" width="40" height="16" rx="8" fill="none" stroke="currentColor" stroke-width="4" stroke-linejoin="round"></rect><path d="M36 14C37.1046 14 38 13.1046 38 12C38 10.8954 37.1046 10 36 10C34.8954 10 34 10.8954 34 12C34 13.1046 34.8954 14 36 14Z" fill="none" stroke="currentColor" stroke-width="4" stroke-linejoin="round"></path>
                                        <path d="M12 38C13.1046 38 14 37.1046 14 36C14 34.8954 13.1046 34 12 34C10.8954 34 10 34.8954 10 36C10 37.1046 10.8954 38 12 38Z" fill="none" stroke="currentColor" stroke-width="4" stroke-linejoin="round"></path>
                                      </svg>
                                    </span>
                                  </div>`
          frag.appendChild(cycleDiv)
          frag.appendChild(coherentDiv)
          fatherDom[i].insertBefore(frag, Dom[i])
        }
        
        const videoDomPlay = document.getElementsByClassName('vjs-tech')
        const videoDomPlayIndex: any = ref(0)
        let currentTime: any = ref(0)
        let interval: number = 0
        let playIndex: any = ref(0) 

        //获取Canvas上下文对象
        // const canvas: any = document.getElementById('canvas')
        // const ctx = canvas.getContext('2d')
        
        for (let i = 0; i < videoDomPlay.length; i++) {
          videoDomPlay[i].addEventListener('ended', () => {
            (videoDomPlay[i] as any).playing = false
            let interval = Number((videoDomPlay[i] as any).interval)
            if((videoDomPlay[i] as any).interval){
              clearInterval(interval)
            }
            (videoDomPlay[i] as any).interval = null
            if ((document.getElementsByClassName('playN')[i] as HTMLElement)?.style.display == 'block' && (document.getElementsByClassName('coherent')[i] as HTMLElement)?.style.display == 'block') {
              setTimeout(() => {
                (videoDomPlay[i] as HTMLVideoElement)?.play()
              }, 0)
            }
          })       
          videoDomPlay[i].addEventListener('pause', () => {
            (videoDomPlay[i] as any).playing = false
            let interval = Number((videoDomPlay[i] as any).interval)
            if ((videoDomPlay[i] as any).interval) {
              clearInterval(interval)
            }
            (videoDomPlay[i] as any).interval = null
          })       
          videoDomPlay[i].addEventListener('play', (e: any) => {
            //视频渲染
            // function canvasRender(){
            //   window.requestAnimationFrame(canvasRender)
            //   ctx.clearRect(0, 0,canvas.width,canvas.height)
            //   ctx.drawImage(videoDomPlay[i], 0, 0,canvas.width,canvas.height)  //绘制视频
            // }
            // canvasRender()

            currentTime = Math.floor((videoDomPlay[i] as HTMLVideoElement)?.currentTime * 100) / 100

            for (let index = 0; index < videoMarker[i].length; index++) {
              if (currentTime < videoMarker[i][0].time) {
                playIndex.value = -1
                break
              } else if (currentTime >= videoMarker[i][index].time) {
                playIndex.value = index
              }
            }
            
            (videoDomPlay[i] as any).playing = true
            if (!(videoDomPlay[i] as any).interval) {
              interval = setInterval(() => {
                if (!videoDomPlay[i] || !(videoDomPlay[i] as any).playing) {
                  clearInterval(interval)
                }
                currentTime = Math.floor((videoDomPlay[i] as HTMLVideoElement)?.currentTime * 100) / 100

                if ((document.getElementsByClassName('noCoherent')[i] as HTMLElement)?.style.display == 'block') {
                  for (let index = 0; index < videoMarker[i].length; index++) {
                    if (currentTime < videoMarker[i][0].time) {
                      videoDomPlayIndex.value = -1
                      break
                    } else if (currentTime >= videoMarker[i][index].time) {
                      videoDomPlayIndex.value = index
                    }
                  }
                  
                  if (videoMarker[i].length > 0) {
                    // 单次
                    if ((document.getElementsByClassName('playOne')[i] as HTMLElement)?.style.display == 'block') {
                      if (videoDomPlayIndex.value > playIndex.value) {
                        (videoDomPlay[i] as HTMLVideoElement)?.pause()
                        playIndex.value = videoDomPlayIndex.value
                      }
                    }
                    // 循环
                    if((document.getElementsByClassName('playN')[i] as HTMLElement)?.style.display == 'block') {
                      if (videoDomPlayIndex.value > playIndex.value) {
                        if (playIndex.value == -1) {
                          (videoDomPlay[i] as HTMLVideoElement).currentTime = 0
                        } else {
                          (videoDomPlay[i] as HTMLVideoElement).currentTime = videoMarker[i][playIndex.value].time
                        }
                      } else if (videoDomPlayIndex.value == playIndex.value && (videoDomPlay[i] as HTMLVideoElement)?.currentTime == (videoDomPlay[i] as HTMLVideoElement)?.duration) {
                        (videoDomPlay[i] as HTMLVideoElement).currentTime = videoMarker[i][playIndex.value].time
                        setTimeout(() => {
                          (videoDomPlay[i] as HTMLVideoElement)?.play()
                        }, 0)
                      }
                    }
                  }
                }
              }, 10) as number
              (videoDomPlay[i] as any).interval = interval
            }            
          })
        }
      })
    }
      
    onMounted(() => {
      videoPlay(slides.value)
      window.addEventListener('resize', windowResizeListener)
      // 侦听、关闭定时器
      window.addEventListener('message', (e: any) => {
        if (e.data == '关闭init定时器' && e.origin == 'https://courseware.brimfar.online') {
          window.clearInterval(intervalTime)
        }
      }) 
    })
    onUnmounted(() => {
      window.removeEventListener('resize', windowResizeListener)
    })

    // 执行元素的入场动画
    const runAnimation = () => {
      const prefix = 'animate__'
      const animation = animations.value[animationIndex.value]
      animationIndex.value += 1

      const elRef = document.querySelector(`#screen-element-${animation.elId} [class^=base-element-]`) as HTMLElement
      if (elRef) {
        if(animation.isEnter === true){
            elRef.style.opacity = '1'
          }
        const animationName = `${prefix}${animation.type}`
        elRef.classList.add(`${prefix}animated`, animationName)

        const handleAnimationEnd = () => {
          if(animation.isEnter === false){
            elRef.style.opacity = '0'
          }
          elRef.classList.remove(`${prefix}animated`, animationName)
        }
        elRef.addEventListener('animationend', handleAnimationEnd, { once: true })
      }
    }

    // 关闭自动播放
    const autoPlayTimer = ref(0)
    const closeAutoPlay = () => {
      if (autoPlayTimer.value) {
        clearInterval(autoPlayTimer.value)
        autoPlayTimer.value = 0
      }
      postMsg('取消自动放映', '')
    }
    onUnmounted(closeAutoPlay)

    // 向上/向下播放
    // 遇到元素动画时，优先执行动画播放，无动画则执行翻页
    // 向上播放遇到动画时，仅撤销到动画执行前的状态，不需要反向播放动画
    const execPrev = (type: any = false) => {
      if (type) {
        postMsg('mouse',  {deltaY: -100})
      }
      if (animations.value.length && animationIndex.value > 0) {
        animationIndex.value -= 1
      }
      else if (slideIndex.value > 0) {
        store.commit(MutationTypes.UPDATE_SLIDE_INDEX, slideIndex.value - 1)
        const lastIndex = animations.value ? animations.value.length : 0
        animationIndex.value = lastIndex
      }
      else {
        message.success('已经是第一页了')
      }

      for (let index = 0; index < document.getElementsByTagName('video').length; index++) {
        document.getElementsByTagName('video')[index].pause()
      }

      for (let index = 0; index < document.getElementsByTagName('video').length; index++) {
        if (document.getElementsByTagName('audio')[index]) {
          document.getElementsByTagName('audio')[index].pause()
        }
      }
    }
    const execNext = (type: any = false) => {
      if (type) {
        postMsg('mouse',  {deltaY: 100})
      }
      if (animations.value.length && animationIndex.value < animations.value.length) {
        runAnimation()
      }
      else if (slideIndex.value < slides.value.length - 1) {
        store.commit(MutationTypes.UPDATE_SLIDE_INDEX, slideIndex.value + 1)
        animationIndex.value = 0
      }
      else {
        message.success('已经是最后一页了')
        closeAutoPlay()
      }

      for (let index = 0; index < document.getElementsByTagName('video').length; index++) {
        document.getElementsByTagName('video')[index].pause()
      }
    

      for (let index = 0; index < document.getElementsByTagName('video').length; index++) {
        if (document.getElementsByTagName('audio')[index]) {
          document.getElementsByTagName('audio')[index].pause()
        }
      }
    }

    // 自动播放
    const autoPlay = () => {
      closeAutoPlay()
      message.success('开始自动放映')
      autoPlayTimer.value = setInterval(execNext, 2500)
      postMsg('自动放映', '')
    }

    // 鼠标滚动翻页
    const mousewheelListener = throttle(function(e: WheelEvent) {
      let flag = false
      if (e.target) {
        for (let i = 0; i < (e as any).path.length; i++) {
          if (((e as any).path[i] as HTMLCanvasElement).className == 'knowledgePoints' && ((e as any).path[i] as HTMLCanvasElement).scrollHeight > ((e as any).path[i] as HTMLCanvasElement).clientHeight) {
            flag = true
            return
          }
        }
      }
      if (flag) {
        return
      }
      if (e.deltaY < 0) {
        execPrev()
      } else if (e.deltaY > 0) {
        execNext()
      }
      // console.log(e.deltaY)
      postMsg('mouse',  {deltaY: e.deltaY})
      
    }, 300, { leading: true, trailing: false })

    // 快捷键翻页
    const keydownListener = (e: KeyboardEvent) => {
      const key = e.key.toUpperCase()
      if (key === KEYS.UP || key === KEYS.LEFT) execPrev()
      else if (
        key === KEYS.DOWN || 
        key === KEYS.RIGHT ||
        key === KEYS.SPACE || 
        key === KEYS.ENTER
      ) execNext()
      // console.log(key)
      postMsg('keydown', {'key': e.key})
    }

    onMounted(() => {
      document.addEventListener('keydown', keydownListener)
    })
    onUnmounted(() => {
      document.removeEventListener('keydown', keydownListener)
    })

    // 切换到上一张/上一张幻灯片（无视元素的入场动画）
    const turnPrevSlide = () => {
      store.commit(MutationTypes.UPDATE_SLIDE_INDEX, slideIndex.value - 1)
      animationIndex.value = 0
    }
    const turnNextSlide = () => {
      store.commit(MutationTypes.UPDATE_SLIDE_INDEX, slideIndex.value + 1)
      animationIndex.value = 0
    }

    // 切换幻灯片到指定的页面
    const turnSlideToIndex = (index: number) => {
      slideThumbnailModelVisible.value = false
      store.commit(MutationTypes.UPDATE_SLIDE_INDEX, index)
      animationIndex.value = 0
    }

    const contextmenus = (): ContextmenuItem[] => {
      return [
        {
          text: '上一页',
          subText: '↑ ←',
          disable: slideIndex.value <= 0,
          handler: () => {
            turnPrevSlide()
            postMsg('上一页', '')
          },
        },
        {
          text: '下一页',
          subText: '↓ →',
          disable: slideIndex.value >= slides.value.length - 1,
          handler: () => {
            turnNextSlide()
            postMsg('下一页', '')
          },
        },
        {
          text: '第一页',
          disable: slideIndex.value === 0,
          handler: () => {
            turnSlideToIndex(0)
            postMsg('第一页', '')
          },
        },
        {
          text: '最后一页',
          disable: slideIndex.value === slides.value.length - 1,
          handler: () => {
            turnSlideToIndex(slides.value.length - 1)
            postMsg('最后一页', '')
          },
        },
        { divider: true },
        {
          text: '显示页码',
          subText: showPageNumber.value ? '√' : '',
          handler: () => showPageNumber.value = !showPageNumber.value,
        },
        {
          text: '查看所有幻灯片',
          handler: () => slideThumbnailModelVisible.value = true,
        },
        {
          text: '画笔',
          handler: () => writingBoardToolVisible.value = true,
        },
        { divider: true },
        {
          text: autoPlayTimer.value ? '取消自动放映' : '自动放映',
          handler: autoPlayTimer.value ? closeAutoPlay : autoPlay,
        },
        {
          text: '结束放映',
          subText: 'ESC',
          handler: closeShow,
        },
      ]
    }
    
    provide('slideScale', scale)

    nextTick(() => {
      const videoId: any[] = reactive([])
      for (let index = 0; index < videoDom.length; index++) {
        videoDom[index].addEventListener('play',() => {
            postMsg('播放视频', videoDom[index].id)
        })
        videoDom[index].addEventListener('pause',() => {
            postMsg('暂停视频', videoDom[index].id)
        })
        videoDom[index].addEventListener('ratechange',() => {
          postMsg('视频播放速度',{
            videoId: videoDom[index].id,
            playbackRate: (videoDom[index] as HTMLVideoElement).playbackRate
            })
        })
        videoDom[index].addEventListener('seeked',(e: any) => {
            const videoIndex = <HTMLVideoElement>videoDom[index]
            postMsg('改变进度条', {
              videoId: videoDom[index].id,
              currentTime: videoIndex.currentTime
            })
        })
        videoId.push(videoDom[index].id)
        // videoDom[index].addEventListener('volumechange',() => {
        //     postMsg('改变音量', videoDom[index].id)
        // })
      }
      for (let i = 0; i < audioDom.length; i++) {
        audioDom[i].addEventListener('play', (e: any) => {
          postMsg('播放音频', i)
        })
        audioDom[i].addEventListener('pause', (e: any) => {
          postMsg('暂停音频', i)
        })
        audioDom[i].addEventListener('seeked', (e: any) => {
          postMsg('改变音频进度条', {
            audioIndex: i,
            currentTime: (audioDom[i] as HTMLAudioElement).currentTime
          })
        })
      }
      // 监听video进入全屏    
      // document.addEventListener('fullscreenchange', (e: any) => {
        // if (document.fullscreenElement && videoId.includes(e.target.id + '_html5_api')) {
        //   postMsg('video进入全屏', e.target.id + '_html5_api')
        // } 
        // if (!document.fullscreenElement && videoId.includes(e.target.id + '_html5_api')) {
        //   postMsg('video退出全屏', e.target.id + '_html5_api')
        // }
      // })
    })

    nextTick(() => {
      setTimeout(() => {
        if(document.getElementById('toolsTwo')) {
          (document.getElementById('toolsTwo') as HTMLElement).style.opacity = '0'
        }
      }, 5000)
    })
    
    return {
      slides,
      slideIndex,
      currentSlide,
      slideWidth,
      slideHeight,
      scale,
      mousewheelListener,
      animationIndex,
      contextmenus,
      execPrev,
      execNext,
      slideThumbnailModelVisible,
      turnSlideToIndex,
      writingBoardToolVisible,
      showPageNumber,
      preview,
      closeShow,
      openAudienceWindow,
      showAudience,
      changeWriting,
      changeWritingColor
    }
  },
})
