
import { computed, defineComponent, PropType } from 'vue'
import { useStore } from '@/store'
import { ElementTypes, PPTElement } from '@/types/slides'

import BaseAudioElement from '@/views/componentsnotesView/element/AudioElement/BaseAudioElementLeft.vue'
import BaseVideoElement from '@/views/componentsnotesView/element/VideoElement/BaseVideoElementcopy.vue'
import BaseImageElement from '@/views/componentsnotesView/element/ImageElement/BaseImageElement.vue'
import BaseTextElement from '@/views/componentsnotesView/element/TextElement/BaseTextElement.vue'
import BaseShapeElement from '@/views/componentsnotesView/element/ShapeElement/BaseShapeElement.vue'
import BaseLineElement from '@/views/componentsnotesView/element/LineElement/BaseLineElement.vue'
import BaseChartElement from '@/views/componentsnotesView/element/ChartElement/BaseChartElement.vue'
import BaseTableElement from '@/views/componentsnotesView/element/TableElement/BaseTableElement.vue'

export default defineComponent({
  name: 'base-element',
  props: {
    elementInfo: {
      type: Object as PropType<PPTElement>,
      required: true,
    },
    elementIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props) {

    const currentElementComponent = computed(() => {
      const elementTypeMap = {
        [ElementTypes.AUDIO]: BaseAudioElement,
        [ElementTypes.VIDEO]: BaseVideoElement,
        [ElementTypes.IMAGE]: BaseImageElement,
        [ElementTypes.TEXT]: BaseTextElement,
        [ElementTypes.SHAPE]: BaseShapeElement,
        [ElementTypes.LINE]: BaseLineElement,
        [ElementTypes.CHART]: BaseChartElement,
        [ElementTypes.TABLE]: BaseTableElement,
      }
      return elementTypeMap[props.elementInfo.type] || null
    })

    return {
      currentElementComponent,
    }
  },
})
