import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.type === 'linear')
    ? (_openBlock(), _createBlock("linearGradient", {
        key: 0,
        id: _ctx.id,
        x1: "0%",
        y1: "0%",
        x2: "100%",
        y2: "0%",
        gradientTransform: `rotate(${_ctx.rotate},0.5,0.5)`
      }, [
        _createVNode("stop", {
          offset: "0%",
          "stop-color": _ctx.color1
        }, null, 8, ["stop-color"]),
        _createVNode("stop", {
          offset: "100%",
          "stop-color": _ctx.color2
        }, null, 8, ["stop-color"])
      ], 8, ["id", "gradientTransform"]))
    : (_openBlock(), _createBlock("radialGradient", {
        key: 1,
        id: _ctx.id
      }, [
        _createVNode("stop", {
          offset: "0%",
          "stop-color": _ctx.color1
        }, null, 8, ["stop-color"]),
        _createVNode("stop", {
          offset: "100%",
          "stop-color": _ctx.color2
        }, null, 8, ["stop-color"])
      ], 8, ["id"]))
}