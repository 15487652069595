
// 打点
import '@/assets/video-markers/videojs-markers.js'
import '@/assets/video-markers/videojs.markers.css'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

import { computed, defineComponent, PropType, reactive, onMounted, onUnmounted, getCurrentInstance, nextTick, ref } from 'vue'
import { PPTVideoElement } from '@/types/slides'
import useElementShadow from '@/views/components/element/hooks/useElementShadow'
import useElementFlip from '@/views/components/element/hooks/useElementFlip'
import useClipImage from './useClipImage'
import useFilter from './useFilter'

import VideoOutline from './VideoOutline/index.vue'

export default defineComponent({
  name: 'base-element-image',
  components: {
    VideoOutline
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTVideoElement>,
      required: true,
    },
  },
  setup(props) {
    const shadow = computed(() => props.elementInfo.shadow)
    const { shadowStyle } = useElementShadow(shadow)

    const flip = computed(() => props.elementInfo.flip)
    const { flipStyle } = useElementFlip(flip)
    
    const clip = computed(() => props.elementInfo.clip)
    const { clipShape, imgPosition } = useClipImage(clip)

    const filters = computed(() => props.elementInfo.filters)
    const { filter } = useFilter(filters)

    // 打点
    const mitter = getCurrentInstance()?.appContext.config.globalProperties.mitter
    let playerSlideshow: any = reactive({})
    const videoJs = reactive({
      options: {
        controls: true, // 必填，显示各控制组件
        // autoplay: true, // 是否自动播放
        // preload: 'auto', // 预加载
        // width: '450px',
        // 'max-height': '300px',
        // height: 'auto',
        loop: false, // 是否循环播放
        playbackRates: [0.5, 1, 1.5, 2], // 是否显示倍速
        controlBar: {
          currentTimeDisplay: true,
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: true,
          volumePanel: {
            inline: false // 将音量横向改为纵向
          }
        },
        controlslist: 'nodownload',
        disablePictureInPicture: true
      },
    })  
    let isScroll: any = ref(true)
    onMounted(() => {
      playerSlideshow = videojs('video' + props.elementInfo.id, videoJs.options)
      nextTick(() => {
        playerSlideshow.markers({
          markerStyle: { // 标记样式
            'width': '8px',
            // 'border-radius': '0%',
            'background-color': 'orange'
          },
          markerTip: {
            display: false, // 不显示标记的tip
            text: function(marker: any) {
              return marker.text ? marker.text : ' '
            },
          },
          markers: props.elementInfo.marker ? props.elementInfo.marker : []
        })
        playerSlideshow.on('play', () => {
          mitter.
          emit('onlyPlaySlideshow', props.elementInfo.id)
        })
        
        const domKnow  = document.getElementsByClassName('knowledgePoints')
        const videoDomPlay = document.getElementsByClassName('vjs-tech')

        for (let i = 0; i < videoDomPlay.length; i++) {
          videoDomPlay[i].addEventListener('ended', () => {
            (videoDomPlay[i] as any).playing = false
            let interval = Number((videoDomPlay[i] as any).interval)
            if((videoDomPlay[i] as any).interval){
              clearInterval(interval)
            }
            (videoDomPlay[i] as any).interval = null
          })       
          videoDomPlay[i].addEventListener('pause', () => {
            (videoDomPlay[i] as any).playing = false
            let interval = Number((videoDomPlay[i] as any).interval)
            if ((videoDomPlay[i] as any).interval) {
              clearInterval(interval)
            }
            (videoDomPlay[i] as any).interval = null
          }) 
          videoDomPlay[i].addEventListener('play', (e: any) => {
            (videoDomPlay[i] as any).playing = true
            if (!(videoDomPlay[i] as any).interval) {
              let interval = setInterval(() => {
                if (!videoDomPlay[i] || !(videoDomPlay[i] as any).playing) {
                  clearInterval(interval)
                }
                if(isScroll.value){
                  if (domKnow[i].scrollHeight > domKnow[i].clientHeight) {
                    domKnow[i].scrollTop  += 1
                  }
                  if (domKnow[i].scrollHeight <= (domKnow[i].clientHeight + domKnow[i].scrollTop + 1)) {
                    domKnow[i].scrollTop = 0
                  }
                }
              }, 125) as number
              (videoDomPlay[i] as any).interval = interval
            }
          })
        }
      })
    })

    onUnmounted(() => {
      playerSlideshow.dispose()
    })

    let pointIndex: any = ref(0)
    window.addEventListener('message', (e: any) => {
      if (e.origin == 'https://courseware.brimfar.online') {
        if(e.data.type == "切换知识点") {
          pointIndex.value = e.data.index
        }
        if (e.data.type == "切换滚动") {
          isScroll.value = e.data.scroll
        }
      }
    })
    return {
      imgPosition,
      filter,
      flipStyle,
      shadowStyle,
      clipShape,
      pointIndex
    }
  },
})
