
import { computed, defineComponent, PropType } from 'vue'
import { useStore } from '@/store'

import ThumbnailSlide from '@/views/components/ThumbnailSlide/index.vue'

export default defineComponent({
  name: 'slide-thumbnails',
  components: {
    ThumbnailSlide,
  },
  props: {
    turnSlideToIndex: {
      type: Function as PropType<(index: number) => void>,
    },
  },
  setup(props, { emit }) {
    const store = useStore()
    const slides = computed(() => store.state.slides)
    const slideIndex = computed(() => store.state.slideIndex)

    const close = () => emit('close')

    return {
      slides,
      slideIndex,
      close,
    }
  },
})
