
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'
import { saveAs } from 'file-saver'

export default defineComponent({
  name: 'export-dialog',
  setup(props, { emit }) {
    const store = useStore()
    const slides = computed(() => store.state.slides)


    const exportJSON = () => {
      const blob = new Blob([JSON.stringify(slides.value)], { type: '' })
      saveAs(blob, 'pptist_slides.json')
    }

    return {
      slides,
      exportJSON,
      emit,
    }
  },
})
