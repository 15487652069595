export const ANIMATIONSOUT = [
  {
    type: 'bounce',
    name: '弹跳',
    children: [
      { name: '弹出', value: 'bounceOut' },
      { name: '向左弹出', value: 'bounceOutLeft' },
      { name: '向右弹出', value: 'bounceOutRight' },
      { name: '向上弹出', value: 'bounceOutUp' },
      { name: '向下弹出', value: 'bounceOutDown' },
    ],
  },
  {
    type: 'fade',
    name: '淡出',
    children: [
      { name: '淡出', value: 'fadeOut' },
      { name: '向下淡出', value: 'fadeOutDown' },
      { name: '向下长距淡出', value: 'fadeOutDownBig' },
      { name: '向右淡出', value: 'fadeOutLeft' },
      { name: '向右长距淡出', value: 'fadeOutLeftBig' },
      { name: '向左淡出', value: 'fadeOutRight' },
      { name: '向左长距淡出', value: 'fadeOutRightBig' },
      { name: '向上淡出', value: 'fadeOutUp' },
      { name: '向上长距淡出', value: 'fadeOutUpBig' },
      { name: '从左上淡出', value: 'fadeOutTopLeft' },
      { name: '从右上淡出', value: 'fadeOutTopRight' },
      { name: '从左下淡出', value: 'fadeOutBottomLeft' },
      { name: '从右下淡出', value: 'fadeOutBottomRight' },
    ],
  },
  {
    type: 'rotate',
    name: '旋转',
    children: [
      { name: '旋转进出', value: 'rotateOut' },
      { name: '绕左下旋转进出', value: 'rotateOutDownLeft' },
      { name: '绕右下旋转进出', value: 'rotateOutDownRight' },
      { name: '绕左上旋转进出', value: 'rotateOutUpLeft' },
      { name: '绕右上旋转进出', value: 'rotateOutUpRight' },
    ],
  },
  {
    type: 'zoom',
    name: '缩放',
    children: [
      { name: '放大进出', value: 'zoomOut' },
      { name: '向下放大进出', value: 'zoomOutDown' },
      { name: '从左放大进出', value: 'zoomOutLeft' },
      { name: '从右放大进出', value: 'zoomOutRight' },
      { name: '向上放大进出', value: 'zoomOutUp' },
    ],
  },
  {
    type: 'slide',
    name: '滑出',
    children: [
      { name: '向下滑出', value: 'slideOutDown' },
      { name: '从右滑出', value: 'slideOutLeft' },
      { name: '从左滑出', value: 'slideOutRight' },
      { name: '向上滑出', value: 'slideOutUp' },
    ],
  },
  {
    type: 'flip',
    name: '翻转',
    children: [
      { name: 'X轴翻转进出', value: 'flipOutX' },
      { name: 'Y轴翻转进出', value: 'flipOutY' },
    ],
  },
  {
    type: 'back',
    name: '放大滑出',
    children: [
      { name: '向下放大滑出', value: 'backOutDown' },
      { name: '从左放大滑出', value: 'backOutLeft' },
      { name: '从右放大滑出', value: 'backOutRight' },
      { name: '向上放大滑出', value: 'backOutUp' },
    ],
  },
  {
    type: 'lightSpeed',
    name: '飞出',
    children: [
      { name: '从右飞出', value: 'lightSpeedOutRight' },
      { name: '从左飞出', value: 'lightSpeedOutLeft' },
    ],
  },
  {
    type: 'bounce',
    name: '弹跳',
    children: [
      { name: '弹出', value: 'bounceOut' },
      { name: '向左弹出', value: 'bounceOutLeft' },
      { name: '向右弹出', value: 'bounceOutRight' },
      { name: '向上弹出', value: 'bounceOutUp' },
      { name: '向下弹出', value: 'bounceOutDown' },
    ],
  },
]