import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "divStyle"
}
const _hoisted_2 = {
  key: 1,
  class: "audienceStyle"
}
const _hoisted_3 = {
  key: 2,
  class: "divStyle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Screen = _resolveComponent("Screen")
  const _component_Editor = _resolveComponent("Editor")
  const _component_IconFullScreenOne = _resolveComponent("IconFullScreenOne")
  const _component_Tooltip = _resolveComponent("Tooltip")
  const _component_audience = _resolveComponent("audience")
  const _component_notes_View = _resolveComponent("notes-View")

  return (_ctx.remarkView)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        (_ctx.preview)
          ? (_openBlock(), _createBlock(_component_Screen, { key: 0 }))
          : (!_ctx.screening)
            ? (_openBlock(), _createBlock(_component_Editor, { key: 1 }))
            : (_openBlock(), _createBlock(_component_Screen, { key: 2 }))
      ]))
    : (_ctx.AudienceCondition)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode("div", null, [
            _createVNode(_component_Tooltip, {
              mouseLeaveDelay: 0,
              title: "进入全屏"
            }, {
              default: _withCtx(() => [
                (_ctx.showIconFullScreenOne)
                  ? (_openBlock(), _createBlock(_component_IconFullScreenOne, {
                      key: 0,
                      onClick: _ctx.openEnterScreening,
                      class: "colorStyle"
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_audience)
        ]))
      : (_openBlock(), _createBlock("div", _hoisted_3, [
          _createVNode(_component_notes_View)
        ]))
}