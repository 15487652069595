
import { computed, defineComponent, nextTick, PropType, getCurrentInstance } from 'vue'
import { PPTAudioElement } from '@/types/slides'
import useElementShadow from '@/views/components/element/hooks/useElementShadow'
import useElementFlip from '@/views/components/element/hooks/useElementFlip'
import useClipImage from './useClipAudio'
import useFilter from './useFilter'

import AudioOutline from './AudioOutline/index.vue'

export default defineComponent({
  name: 'base-element-image',
  components: {
    AudioOutline,
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTAudioElement>,
      required: true,
    },
  },
  setup(props) {
    const shadow = computed(() => props.elementInfo.shadow)
    const { shadowStyle } = useElementShadow(shadow)

    const flip = computed(() => props.elementInfo.flip)
    const { flipStyle } = useElementFlip(flip)
    
    const clip = computed(() => props.elementInfo.clip)
    const { clipShape, imgPosition } = useClipImage(clip)

    const filters = computed(() => props.elementInfo.filters)
    const { filter } = useFilter(filters)

    const mitter = getCurrentInstance()?.appContext.config.globalProperties.mitter
    const audioDom = document.getElementsByClassName('audioPPT')

    nextTick(() => {
      for (let index = 0; index < audioDom.length; index++) {
        audioDom[index].addEventListener('play', () => {
          mitter.emit('onlyPlayAudioScreen', index)
        })
      }
    })

    return {
      imgPosition,
      filter,
      flipStyle,
      shadowStyle,
      clipShape,
    }
  },
})
