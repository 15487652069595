
import { computed, defineComponent, getCurrentInstance, nextTick, onMounted, ref } from 'vue'
import { MutationTypes, useStore } from '@/store'
import { fillDigit } from '@/utils/common'
import axios from 'axios'

import Draggable from 'vuedraggable'
import ThumbnailSlide from '@/views/components/ThumbnailSlide/index.vue'
import { message } from 'ant-design-vue'

export default defineComponent({
  name: 'resourcePool',
  components: {
    Draggable,
    ThumbnailSlide,
  },
  
  setup() {
    const store = useStore()
    const slides = computed(() => store.state.dragSlides)
    const slideIndex = computed(() => store.state.dragSlideIndex)
    const mitter = getCurrentInstance()?.appContext.config.globalProperties.mitter


    const dragStart = (index: any) => {
      // event.dataTransfer.setData('data', JSON.parse(JSON.stringify(slides.value)))
      nextTick(() => {
        // console.log(index)
        mitter.emit('dragData', JSON.parse(JSON.stringify(slides.value))[index])
      })
      // const allowDrop = JSON.parse(JSON.stringify(slides.value))
    }

    const allowDrop = (event: any) => {
      // event.preventDefault()
    }

    const copyInnerTextOfCell = (event: any) => {
      // const copyVal = document.getElementById('preview')
      document.execCommand('copy')
      // console.log(document.execCommand('copy'))
    }

    const importAll = (event: any) => {
      store.state.dragSlides && store.commit(MutationTypes.ADD_SLIDE, store.state.dragSlides)
    }
  
    const baseUrl = getCurrentInstance()?.appContext.config.globalProperties.baseUrl
    axios.get(baseUrl + '/courseResource/detail', { params: {id: sessionStorage.getItem('ResourcesID')}, headers: {'Authorization': sessionStorage.getItem('token') }}).then( res => {
      if(res.data.status === 200) {
        // console.log(res.data.data.pptContent)
        res.data.data && res.data.data.pptContent && store.commit(MutationTypes.SET_DRAG_SLIDES, JSON.parse(res.data.data.pptContent))
      }else {
        message.error(res.data.message)
      }
    }).catch((e: any)=>{
      if (e.message.indexOf('403') != '-1') {
        message.error('身份信息已过期、请重新登录!')
      }
    })

    return {
      slides,
      slideIndex,
      fillDigit,
      ModalText: 'Content of the modal',
      confirmLoading: false,
      dragStart,
      allowDrop,
      copyInnerTextOfCell,
      importAll
    }
  },
  
})
