
import { defineComponent, onMounted, PropType, reactive, ref, computed } from 'vue'
import { useStore } from '@/store'

const penSize = 6
const rubberSize = 80

export default defineComponent({
  name: 'writing-board',
  props: {
    color: {
      type: String,
      default: '#ffcc00',
    },
    model: {
      type: String as PropType<'pen' | 'eraser'>,
      default: 'pen',
    },
  },
  setup(props) {
    const store = useStore()
    const slideIndex = computed(() => store.state.slideIndex)
    let ctx: CanvasRenderingContext2D | null = null
    const writingBoardRef = ref<HTMLElement>()
    const canvasRef = ref<HTMLCanvasElement>()
    const modelll = ref('pen')
    const defColor = ref('#e2534d')

    let lastPos = {
      x: 0,
      y: 0,
    }
    let isMouseDown = false
    let lastTime = 0
    let lastLineWidth = -1

    // 鼠标位置坐标：用于画笔或橡皮位置跟随
    const mouse = reactive({
      x: 0,
      y: 0,
    })

    // 更新鼠标位置坐标
    const updateMousePosition = (x: number, y: number) => {
      mouse.x = x
      mouse.y = y
    }
    
    // 鼠标是否处在画布范围内：处在范围内才会显示画笔或橡皮
    const mouseInCanvas = ref(false)


    // 初始化画布
    const initCanvas = () => {
      if (!canvasRef.value || !writingBoardRef.value) return

      ctx = canvasRef.value.getContext('2d')
      if (!ctx) return

      
      const canvasImg = new Image()
      const base64 =  ref('')
      
      canvasRef.value.width = document.getElementsByClassName('slide-content')[slideIndex.value].getBoundingClientRect().width 
      canvasRef.value.height = document.getElementsByClassName('slide-content')[slideIndex.value].getBoundingClientRect().height 
      writingBoardRef.value.style.left=document.getElementsByClassName('slide-content')[slideIndex.value].getBoundingClientRect().x + 'px'
      writingBoardRef.value.style.top=document.getElementsByClassName('slide-content')[slideIndex.value].getBoundingClientRect().y + 'px'

      // canvasRef.value.width = writingBoardRef.value.clientWidth
      // canvasRef.value.height = writingBoardRef.value.clientHeight

      canvasRef.value.style.width = document.getElementsByClassName('slide-content')[slideIndex.value].getBoundingClientRect().width + 'px'
      canvasRef.value.style.height = document.getElementsByClassName('slide-content')[slideIndex.value].getBoundingClientRect().height + 'px'
      writingBoardRef.value.style.width = document.getElementsByClassName('slide-content')[slideIndex.value].getBoundingClientRect().width + 'px'
      writingBoardRef.value.style.height = document.getElementsByClassName('slide-content')[slideIndex.value].getBoundingClientRect().height + 'px'

      ctx.lineCap = 'round'
      ctx.lineJoin = 'round'

      window.addEventListener('resize', () => {
        if (!canvasRef.value || !writingBoardRef.value) return
        
        base64.value = canvasRef.value.toDataURL()

        canvasRef.value.width = document.getElementsByClassName('slide-content')[slideIndex.value].getBoundingClientRect().width 
        canvasRef.value.height = document.getElementsByClassName('slide-content')[slideIndex.value].getBoundingClientRect().height 
        writingBoardRef.value.style.left=document.getElementsByClassName('slide-content')[slideIndex.value].getBoundingClientRect().x + 'px'
        writingBoardRef.value.style.top=document.getElementsByClassName('slide-content')[slideIndex.value].getBoundingClientRect().y + 'px'

        // canvasRef.value.width = writingBoardRef.value.clientWidth
        // canvasRef.value.height = writingBoardRef.value.clientHeight

        canvasRef.value.style.width = document.getElementsByClassName('slide-content')[slideIndex.value].getBoundingClientRect().width + 'px'
        canvasRef.value.style.height = document.getElementsByClassName('slide-content')[slideIndex.value].getBoundingClientRect().height + 'px'
        writingBoardRef.value.style.width = document.getElementsByClassName('slide-content')[slideIndex.value].getBoundingClientRect().width + 'px'
        writingBoardRef.value.style.height = document.getElementsByClassName('slide-content')[slideIndex.value].getBoundingClientRect().height + 'px'

        
        canvasImg.onload = function() {
          if (!canvasRef.value) return
          ctx?.drawImage(canvasImg, 0, 0, canvasRef.value.width, canvasRef.value.height)
        }
        canvasImg.src = base64.value
      })
    }
    onMounted(initCanvas)

    // 绘制画笔墨迹方法
    const draw = (posX: number, posY: number, lineWidth: number) => {
      if (!ctx) return

      const lastPosX = lastPos.x
      const lastPosY = lastPos.y

      ctx.lineWidth = lineWidth
      ctx.strokeStyle = defColor.value
      ctx.beginPath()
      ctx.moveTo(lastPosX, lastPosY)
      ctx.lineTo(posX, posY)
      ctx.stroke()
      ctx.closePath()
    }

    // 擦除墨迹方法
    const erase = (posX: number, posY: number) => {
      if (!ctx || !canvasRef.value) return
      const lastPosX = lastPos.x
      const lastPosY = lastPos.y

      const radius = rubberSize / 2

      const sinRadius = radius * Math.sin(Math.atan((posY - lastPosY) / (posX - lastPosX)))
      const cosRadius = radius * Math.cos(Math.atan((posY - lastPosY) / (posX - lastPosX)))
      const rectPoint1: [number, number] = [lastPosX + sinRadius, lastPosY - cosRadius]
      const rectPoint2: [number, number] = [lastPosX - sinRadius, lastPosY + cosRadius]
      const rectPoint3: [number, number] = [posX + sinRadius, posY - cosRadius]
      const rectPoint4: [number, number] = [posX - sinRadius, posY + cosRadius]

      ctx.save()
      ctx.beginPath()
      ctx.arc(posX, posY, radius, 0, Math.PI * 2)
      ctx.clip()
      ctx.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height)
      ctx.restore()

      ctx.save()
      ctx.beginPath()
      ctx.moveTo(...rectPoint1)
      ctx.lineTo(...rectPoint3)
      ctx.lineTo(...rectPoint4)
      ctx.lineTo(...rectPoint2)
      ctx.closePath()
      ctx.clip()
      ctx.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height)
      ctx.restore()
    }

    // 计算鼠标两次移动之间的距离
    const getDistance = (posX: number, posY: number) => {
      const lastPosX = lastPos.x
      const lastPosY = lastPos.y
      return Math.sqrt((posX - lastPosX) * (posX - lastPosX) + (posY - lastPosY) * (posY - lastPosY))
    }

    // 根据鼠标两次移动之间的距离s和时间t计算绘制速度，速度越快，墨迹越细
    const getLineWidth = (s: number, t: number) => {
      const maxV = 10
      const minV = 0.1
      const maxWidth = penSize
      const minWidth = 3
      const v = s / t
      let lineWidth

      if (v <= minV) lineWidth = maxWidth
      else if (v >= maxV) lineWidth = minWidth
      else lineWidth = maxWidth - v / maxV * maxWidth

      if (lastLineWidth === -1) return lineWidth
      return lineWidth * 1 / 3 + lastLineWidth * 2 / 3
    }

    // 路径操作
    const handleMove = (x: number, y: number) => {
      const time = new Date().getTime()

      if (modelll.value === 'pen') {
        const s = getDistance(x, y)
        const t = time - lastTime
        const lineWidth = getLineWidth(s, t)

        draw(x, y, lineWidth)
        lastLineWidth = lineWidth
      }
      else erase(x, y)

      lastPos = {x, y}
      lastTime = new Date().getTime()
    }

  
    let openAudienceWindow =  ref({postMessage: (index: any, http: any)=> {}})
    openAudienceWindow.value = window.opener ? window.opener : openAudienceWindow.value
    // 处理鼠标（触摸）事件
    // 准备开始绘制/擦除墨迹（落笔）
    const handleMousedown = (e: MouseEvent | TouchEvent) => {
      const x = e instanceof MouseEvent ? e.offsetX : e.changedTouches[0].pageX
      const y = e instanceof MouseEvent ? e.offsetY : e.changedTouches[0].pageY

      isMouseDown = true
      lastPos = { x, y }
      lastTime = new Date().getTime()

      if (e instanceof TouchEvent) {
        updateMousePosition(x, y)
        mouseInCanvas.value = true
      }
    }

    // 开始绘制/擦除墨迹（移动）
    const handleMousemove = (e: MouseEvent | TouchEvent) => {
      const x = e instanceof MouseEvent ? e.offsetX : e.changedTouches[0].pageX
      const y = e instanceof MouseEvent ? e.offsetY : e.changedTouches[0].pageY

      updateMousePosition(x, y)

      if (isMouseDown) handleMove(x, y)
    }

    // 结束绘制/擦除墨迹（停笔）
    const handleMouseup = () => {
      if (!isMouseDown) return
      isMouseDown = false
    }

    // 清空画布
    const clearCanvas = () => {
      if (!ctx || !canvasRef.value) return
      ctx.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height)
    }

    let x = 0, y = 0
    window.addEventListener('message', (e: any) => {
      if (e.origin == 'https://courseware.brimfar.online') {
        if (!writingBoardRef.value) return
        e.data.x = Number(e.data.x) * writingBoardRef.value.offsetWidth
        e.data.y = Number(e.data.y) * writingBoardRef.value.offsetHeight
        if (e.data.type == '画笔按下') {
          // isMouseDown = true
          lastTime = new Date().getTime()
          x = Number(e.data.x)
          y = Number(e.data.y)
          lastPos = { x, y }
          modelll.value = e.data.model
          // defColor.value =  e.data.color
          mouseInCanvas.value = true
        }
        if (e.data.type == '写字移动') {
          modelll.value = e.data.model
          updateMousePosition(e.data.x, e.data.y)
          handleMove(e.data.x, e.data.y)
        }
        // if (e.data.type == '画笔移动') {
        //   mouseInCanvas.value = true
        //   // defColor.value =  e.data.color
        //   updateMousePosition(e.data.x, e.data.y)
        // }
        if ( e.data.type == '清空画布') {
          clearCanvas()
          mouseInCanvas.value = true
        }
        // 别的页面发来的
        if (e.data.type == '画笔或橡皮') {
          modelll.value = e.data.index
        }
        if (e.data.type == '画笔颜色') {
          modelll.value != 'pen' && (modelll.value = 'pen')
          defColor.value =  e.data.index
        }
      }
    })

    return {
      mouse,
      mouseInCanvas,
      penSize,
      rubberSize,
      writingBoardRef,
      canvasRef,
      handleMousedown,
      handleMousemove,
      handleMouseup,
      clearCanvas,
      modelll,
      defColor
    }
  },
})
