
import { computed, defineComponent, PropType } from 'vue'
import { PPTChartElement } from '@/types/slides'

import ElementOutline from '@/views/components/element/ElementOutline.vue'

export default defineComponent({
  name: 'base-element-chart',
  components: {
    ElementOutline,
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTChartElement>,
      required: true,
    },
  },
  setup(props) {
    const size = computed(() => Math.min(props.elementInfo.width, props.elementInfo.height))

    const chartType = computed(() => {
      const _chartType = props.elementInfo.chartType
      const _options = props.elementInfo.options

      if (_chartType === 'bar') {
        if (_options?.horizontalBars) return 'horizontalBar'
        return 'bar'
      }
      else if (_chartType === 'line') {
        if (_options?.showArea) return 'area'
        else if (_options && _options.showLine === false) return 'scatter'
        return 'line'
      }
      else if (_chartType === 'pie') {
        if (_options?.donut) return 'ring'
        return 'pie'
      }

      return ''
    })

    return {
      size,
      chartType,
    }
  },
})
