
import { computed, PropType, defineComponent } from 'vue'
import { useStore } from '@/store'
import { Slide } from '@/types/slides'
import { VIEWPORT_SIZE } from '@/configs/canvas'
import useSlideBackgroundStyle from '@/hooks/useSlideBackgroundStyle'

import ThumbnailElement from './ThumbnailElement.vue'

export default defineComponent({
  name: 'thumbnail-slide',
  components: {
    ThumbnailElement,
  },
  props: {
    slide: {
      type: Object as PropType<Slide>,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    }
  },
  setup(props) {
    const store = useStore()
    const viewportRatio = computed(() => store.state.viewportRatio)

    const background = computed(() => props.slide.background)
    const { backgroundStyle } = useSlideBackgroundStyle(background)

    const scale = computed(() => props.size / VIEWPORT_SIZE)

    return {
      scale,
      backgroundStyle,
      VIEWPORT_SIZE,
      viewportRatio,
    }
  },
})
