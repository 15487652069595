
import { computed, defineComponent, ref } from 'vue'
import { MutationTypes, useStore } from '@/store'
import { PPTAnimation, PPTElement, Slide } from '@/types/slides'
import { ANIMATIONS } from '@/configs/animation'
import { ANIMATIONSOUT } from '@/configs/animationOut'
import { ELEMENT_TYPE_ZH } from '@/configs/element'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

import Draggable from 'vuedraggable'

const animationTypes: { [key: string]: string } = {}
for (const type of ANIMATIONS) {
  for (const animation of type.children) {
    animationTypes[animation.value] = animation.name
  }
}

const animationOutTypes: { [key: string]: string } = {}
for (const type of ANIMATIONSOUT) {
  for (const animation of type.children) {
    animationOutTypes[animation.value] = animation.name
  }
}

export default defineComponent({
  name: 'element-animation-panel',
  components: {
    Draggable,
  },
  setup() {
    const store = useStore()
    const handleElement = computed<PPTElement>(() => store.getters.handleElement)
    const currentSlideAnimations = computed<PPTAnimation[] | null>(() => store.getters.currentSlideAnimations)
    const currentSlide = computed<Slide>(() => store.getters.currentSlide)

    const hoverPreviewAnimation = ref('')
    const hoverPreviewAnimationOut = ref('')
    const animationPoolVisible = ref(false)
    const animationPoolVisibleOut = ref(false)

    const { addHistorySnapshot } = useHistorySnapshot()

    const animations = ANIMATIONS
    const animationsOut = ANIMATIONSOUT

    // 当前页面的动画列表
    const animationSequence = computed(() => {
      if (!currentSlideAnimations.value) return []
      const animationSequence = []
      for (const animation of currentSlideAnimations.value) {
        const el = currentSlide.value.elements.find(el => el.id === animation.elId)
        if (!el) continue
        const elType = ELEMENT_TYPE_ZH[el.type]
        const animationType = animationTypes[animation.type]

        animationSequence.push({
          ...animation,
          elType,
          animationType,
        })
      }
      return animationSequence
    })

    // 当前选中元素的入场动画信息
    const handleElementAnimation = computed(() => {
      if (!handleElement.value) return null
      const animations = currentSlideAnimations.value || []
      const animation = animations.find(item => item.elId === handleElement.value.id && item.isEnter === true )
      if (!animation) return null
      return animationTypes[animation.type]
    })

    // 当前选中元素的出场动画信息
    const handleElementAnimationOut = computed(() => {
      if (!handleElement.value) return null
      const animations = currentSlideAnimations.value || []
      const animation = animations.find(item => item.elId === handleElement.value.id && item.isEnter === false)
      if (!animation) return null
      return animationOutTypes[animation.type]
    })

    // 删除元素入场动画
    const deleteAnimation = (elId: string) => {
      const animations = (currentSlideAnimations.value as PPTAnimation[]).filter(item => item.elId !== elId )
      store.commit(MutationTypes.UPDATE_SLIDE, { animations })
      addHistorySnapshot()
    }

    // 拖拽修改入场动画顺序后同步数据
    const handleDragEnd = (eventData: { newIndex: number; oldIndex: number }) => {
      const { newIndex, oldIndex } = eventData
      if (oldIndex === newIndex) return

      const animations: PPTAnimation[] = JSON.parse(JSON.stringify(currentSlideAnimations.value))
      const animation = animations[oldIndex]
      animations.splice(oldIndex, 1)
      animations.splice(newIndex, 0, animation)
      
      store.commit(MutationTypes.UPDATE_SLIDE, { animations })
      addHistorySnapshot()
    }

    // 执行入场动画预览
    const runAnimation = (elId: string, animationType: string) => {
      const prefix = 'animate__'
      const elRef = document.querySelector(`#editable-element-${elId} [class^=editable-element-]`)
      if (elRef) {
        const animationName = `${prefix}${animationType}`
        elRef.classList.add(`${prefix}animated`, animationName)

        const handleAnimationEnd = () => {
          elRef.classList.remove(`${prefix}animated`, animationName)
        }
        elRef.addEventListener('animationend', handleAnimationEnd, { once: true })
      }
    }

    // 修改元素入场动画，并执行一次预览
    const updateElementAnimation = (type: string) => {
      const animations = (currentSlideAnimations.value as PPTAnimation[]).map(item => {
        if (item.elId === handleElement.value.id && item.isEnter === true) return { ...item, type }
        return item
      })
      store.commit(MutationTypes.UPDATE_SLIDE, { animations })
      animationPoolVisible.value = false
      addHistorySnapshot()

      runAnimation(handleElement.value.id, type)
    }

    // 修改元素出场动画，并执行一次预览
    const updateElementAnimationOut = (type: string) => {
      const animations = (currentSlideAnimations.value as PPTAnimation[]).map(item => {
        if (item.elId === handleElement.value.id && item.isEnter === false) return { ...item, type }
        return item
      })
      store.commit(MutationTypes.UPDATE_SLIDE, { animations })
      animationPoolVisibleOut.value = false
      addHistorySnapshot()

      runAnimation(handleElement.value.id, type)
    }

    // 添加元素入场动画，并执行一次预览
    const addAnimation = (type: string) => {
      if (handleElementAnimation.value) {
        updateElementAnimation(type)
        return
      }
      const animations: PPTAnimation[] = currentSlideAnimations.value ? JSON.parse(JSON.stringify(currentSlideAnimations.value)) : []
      animations.push({
        elId: handleElement.value.id,
        type,
        isEnter: true,
        duration: 1000,
      })
      store.commit(MutationTypes.UPDATE_SLIDE, { animations })
      animationPoolVisible.value = false
      addHistorySnapshot()

      runAnimation(handleElement.value.id, type)
    }
    // 添加元素出场动画，并执行一次预览
    const addAnimationOut = (type: string) => {
      if (handleElementAnimationOut.value) {
        updateElementAnimationOut(type)
        return
      }
      const animations: PPTAnimation[] = currentSlideAnimations.value ? JSON.parse(JSON.stringify(currentSlideAnimations.value)) : []
      animations.push({
        elId: handleElement.value.id,
        type,
        isEnter: false,
        duration: 1000,
      })
      // console.log(animations)
      store.commit(MutationTypes.UPDATE_SLIDE, { animations })
      animationPoolVisibleOut.value = false
      addHistorySnapshot()

      runAnimation(handleElement.value.id, type)
    }

    return {
      handleElement,
      animationPoolVisible,
      animationPoolVisibleOut,
      animations,
      animationsOut,
      animationSequence,
      hoverPreviewAnimation,
      handleElementAnimation,
      hoverPreviewAnimationOut,
      handleElementAnimationOut,
      addAnimation,
      addAnimationOut,
      deleteAnimation,
      handleDragEnd,
      runAnimation,
    }
  },
})
