
import { computed, defineComponent, provide, ref, reactive, watch, watchEffect, getCurrentInstance, onMounted, nextTick, Events, HtmlHTMLAttributes } from 'vue'
import throttle from 'lodash/throttle'
import { MutationTypes, useStore } from '@/store'
import { ContextmenuItem } from '@/components/Contextmenu/types'
import { PPTElement, Slide } from '@/types/slides'
import { AlignmentLineProps } from '@/types/edit'
import { removeAllRanges } from '@/utils/selection'
import { KEYS } from '@/configs/hotkey'

import useViewportSize from './hooks/useViewportSize'
import useMouseSelection from './hooks/useMouseSelection'
// import useDropImageOrText from './hooks/useDropImageOrText'
import useRotateElement from './hooks/useRotateElement'
import useScaleElement from './hooks/useScaleElement'
import useSelectElement from './hooks/useSelectElement'
import useDragElement from './hooks/useDragElement'
import useDragLineElement from './hooks/useDragLineElement'
import useInsertFromCreateSelection from './hooks/useInsertFromCreateSelection'

import useDeleteElement from '@/hooks/useDeleteElement'
import useCopyAndPasteElement from '@/hooks/useCopyAndPasteElement'
import useSelectAllElement from '@/hooks/useSelectAllElement'
import useScaleCanvas from '@/hooks/useScaleCanvas'
import useScreening from '@/hooks/useScreening'
import useSlideHandler from '@/hooks/useSlideHandler'

import EditableElement from './EditableElement.vue'
import MouseSelection from './MouseSelection.vue'
import ViewportBackground from './ViewportBackground.vue'
import AlignmentLine from './AlignmentLine.vue'
import ElementCreateSelection from './ElementCreateSelection.vue'
import MultiSelectOperate from './Operate/MultiSelectOperate.vue'
import Operate from './Operate/index.vue'

export default defineComponent({
  name: 'editor-canvas',
  components: {
    EditableElement,
    MouseSelection,
    ViewportBackground,
    AlignmentLine,
    ElementCreateSelection,
    MultiSelectOperate,
    Operate,
  },
  setup() {
    const store = useStore()

    const activeElementIdList = computed(() => store.state.activeElementIdList)
    const handleElementId = computed(() => store.state.handleElementId)
    const activeGroupElementId = computed(() => store.state.activeGroupElementId)
    const editorAreaFocus = computed(() => store.state.editorAreaFocus)
    const ctrlKeyState = computed(() => store.state.ctrlKeyState)
    const ctrlOrShiftKeyActive = computed<boolean>(() => store.getters.ctrlOrShiftKeyActive)

    const viewportRef = ref<HTMLElement>()
    const alignmentLines = ref<AlignmentLineProps[]>([])

    watch(handleElementId, () => {
      store.commit(MutationTypes.SET_ACTIVE_GROUP_ELEMENT_ID, '')
    })


    const currentSlide = computed<Slide>(() => store.getters.currentSlide)
    const elementList = ref<PPTElement[]>([])
    
    const setLocalElementList = () => {
      elementList.value = currentSlide.value ? JSON.parse(JSON.stringify(currentSlide.value.elements)) : []
      let videoMarker: any[] = []
      JSON.parse(JSON.stringify(currentSlide.value.elements)).forEach((item: any) => {
        if (item.type == 'video') {
          videoMarker.push(item.marker)
        }
      })
      nextTick(() => { 
        if (elementList.value.length == 0 ) {
          return false
        }
        const Dom = document.getElementsByClassName('vjs-volume-panel')
        const fatherDom = document.getElementsByClassName('vjs-control-bar')
        
        for (let i = 0; i < fatherDom.length; i++) {
          if ((fatherDom[i] as HTMLElement)?.children.length == 19) {
            continue
          }
          const frag = document.createDocumentFragment()
          const cycleDiv = document.createElement('div')
          const coherentDiv = document.createElement('div')
          cycleDiv.innerHTML = `<div class="cycle" style="display: flex; justify-content:center; align-items: center; width: 2em; height: 100%; text-align: center; font-size: 18px; cursor: pointer;">
                                  <span title="播放一次" class="playOne i-icon i-icon-play-once" style="display: block" data-v-1e203a46=""
                                  onclick="javascript: 
                                    document.getElementsByClassName('playOne')[${i}].style.display = 'none'; 
                                    document.getElementsByClassName('playN')[${i}].style.display = 'block';
                                  ">
                                    <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none">
                                      <path d="M43.8233 25.2305C43.7019 25.9889 43.5195 26.727 43.2814 27.4395C42.763 28.9914 41.9801 30.4222 40.9863 31.6785C38.4222 34.9201 34.454 37 30 37H16C9.39697 37 4 31.6785 4 25C4 18.3502 9.39624 13 16 13L44 13" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path>
                                      <path d="M38 7L44 13L38 19" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M24 19V31" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M24 19L21 22L19.5 23.5" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                  </span>
                                  <span title="循环播放" class="playN i-icon i-icon-play-cycle" style="display: none" data-v-1e203a46="" 
                                  onclick="javascript: document.getElementsByClassName('playN')[${i}].style.display = 'none'; document.getElementsByClassName('playOne')[${i}].style.display = 'block'">
                                    <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none">
                                      <path d="M4 25C4 18.3502 9.39624 13 16 13L44 13" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M38 7L44 13L38 19" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M44 23C44 29.6498 38.6038 35 32 35H4" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path>
                                      <path d="M10 41L4 35L10 29" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                  </span>
                                </div>`
          coherentDiv.innerHTML = `<div style="display: flex; justify-content:center; align-items: center; width: 2em; height: 100%; text-align: center; font-size: 18px; cursor: pointer;" >
                                    <span title="连贯播放" class="coherent i-icon i-icon-switch-one" style="display: block"  data-v-1e203a46=""
                                    onclick="javascript: 
                                      document.getElementsByClassName('coherent')[${i}].style.display = 'none'; 
                                      document.getElementsByClassName('noCoherent')[${i}].style.display = 'block';
                                    ">
                                      <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none">
                                        <circle cx="24" cy="24" r="19" fill="none" stroke="currentColor" stroke-width="4"></circle>
                                        <rect x="36.0063" y="19.3335" width="10.5189" height="24.0125" rx="5.25944" transform="rotate(90 36.0063 19.3335)" fill="none" stroke="currentColor" stroke-width="4"></rect><rect x="36.0063" y="29.8524" width="10" height="10" rx="5" transform="rotate(-180 36.0063 29.8524)" stroke="currentColor" stroke-width="4"></rect>
                                      </svg>
                                    </span>
                                    <span title="断点播放" class="noCoherent i-icon i-icon-switch-button" style="display: none" data-v-1e203a46=""
                                    onclick="javascript: 
                                      document.getElementsByClassName('noCoherent')[${i}].style.display = 'none'; 
                                      document.getElementsByClassName('coherent')[${i}].style.display = 'block';
                                    ">
                                      <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none">
                                        <rect x="4" y="4" width="40" height="16" rx="8" fill="none" stroke="currentColor" stroke-width="4" stroke-linejoin="round"></rect><rect x="4" y="28" width="40" height="16" rx="8" fill="none" stroke="currentColor" stroke-width="4" stroke-linejoin="round"></rect><path d="M36 14C37.1046 14 38 13.1046 38 12C38 10.8954 37.1046 10 36 10C34.8954 10 34 10.8954 34 12C34 13.1046 34.8954 14 36 14Z" fill="none" stroke="currentColor" stroke-width="4" stroke-linejoin="round"></path>
                                        <path d="M12 38C13.1046 38 14 37.1046 14 36C14 34.8954 13.1046 34 12 34C10.8954 34 10 34.8954 10 36C10 37.1046 10.8954 38 12 38Z" fill="none" stroke="currentColor" stroke-width="4" stroke-linejoin="round"></path>
                                      </svg>
                                    </span>
                                  </div>`
          frag.appendChild(cycleDiv)
          frag.appendChild(coherentDiv)
          fatherDom[i].insertBefore(frag, Dom[i])
        }
        
        const videoDomPlay = document.getElementsByClassName('vjs-tech')
        const videoDomPlayIndex: any = ref(0)
        let currentTime: any = ref(0)
        let interval: number = 0
        let playIndex: any = ref(0)   
        
        for (let i = 0; i < videoDomPlay.length; i++) {
          videoDomPlay[i].addEventListener('ended', () => {
            (videoDomPlay[i] as any).playing = false
            let interval = Number((videoDomPlay[i] as any).interval)
            if((videoDomPlay[i] as any).interval){
              clearInterval(interval)
            }
            (videoDomPlay[i] as any).interval = null
            if ((document.getElementsByClassName('playN')[i] as HTMLElement)?.style.display == 'block' && (document.getElementsByClassName('coherent')[i] as HTMLElement)?.style.display == 'block') {
              setTimeout(() => {
                (videoDomPlay[i] as HTMLVideoElement)?.play()
              }, 0)
            }
          })       
          videoDomPlay[i].addEventListener('pause', () => {
            (videoDomPlay[i] as any).playing = false
            let interval = Number((videoDomPlay[i] as any).interval)
            if ((videoDomPlay[i] as any).interval) {
              clearInterval(interval)
            }
            (videoDomPlay[i] as any).interval = null
          }) 
          videoDomPlay[i].addEventListener('play', (e: any) => {
            currentTime = Math.floor((videoDomPlay[i] as HTMLVideoElement)?.currentTime * 100) / 100
            
            for (let index = 0; index < videoMarker[i].length; index++) {
              if (currentTime < videoMarker[i][0].time) {
                playIndex.value = -1
                break
              } else if (currentTime >= videoMarker[i][index].time) {
                playIndex.value = index
              }
            }
            
            (videoDomPlay[i] as any).playing = true
            if (!(videoDomPlay[i] as any).interval) {
              interval = setInterval(() => {
                if (!videoDomPlay[i] || !(videoDomPlay[i] as any).playing) {
                  clearInterval(interval)
                }
                currentTime = Math.floor((videoDomPlay[i] as HTMLVideoElement)?.currentTime * 100) / 100

                if ((document.getElementsByClassName('noCoherent')[i] as HTMLElement)?.style.display == 'block') {
                  for (let index = 0; index < videoMarker[i].length; index++) {
                    if (currentTime < videoMarker[i][0].time) {
                      videoDomPlayIndex.value = -1
                      break
                    } else if (currentTime >= videoMarker[i][index].time) {
                      videoDomPlayIndex.value = index
                    }
                  }
                  
                  if (videoMarker[i].length > 0) {
                    // 单次
                    if ((document.getElementsByClassName('playOne')[i] as HTMLElement)?.style.display == 'block') {
                      if (videoDomPlayIndex.value > playIndex.value) {
                        (videoDomPlay[i] as HTMLVideoElement)?.pause()
                        playIndex.value = videoDomPlayIndex.value
                      }
                    }
                    // 循环
                    if((document.getElementsByClassName('playN')[i] as HTMLElement)?.style.display == 'block') {
                      if (videoDomPlayIndex.value > playIndex.value) {
                        if (playIndex.value == -1) {
                          (videoDomPlay[i] as HTMLVideoElement).currentTime = 0
                        } else {
                          (videoDomPlay[i] as HTMLVideoElement).currentTime = videoMarker[i][playIndex.value].time
                        }
                      } else if (videoDomPlayIndex.value == playIndex.value && (videoDomPlay[i] as HTMLVideoElement)?.currentTime == (videoDomPlay[i] as HTMLVideoElement)?.duration) {
                        (videoDomPlay[i] as HTMLVideoElement).currentTime = videoMarker[i][playIndex.value].time
                        setTimeout(() => {
                          (videoDomPlay[i] as HTMLVideoElement)?.play()
                        }, 0)
                      }
                    }
                  }
                }
              }, 10) as number
              (videoDomPlay[i] as any).interval = interval
            }            
          })
        }
      })
    }
    watchEffect(setLocalElementList)

    const canvasRef = ref<HTMLElement>()
    const canvasScale = computed(() => store.state.canvasScale)
    const { viewportStyles } = useViewportSize(canvasRef)

    // useDropImageOrText(canvasRef)

    const { mouseSelectionState, updateMouseSelection } = useMouseSelection(elementList, viewportRef)

    const { dragElement } = useDragElement(elementList, alignmentLines)
    const { dragLineElement } = useDragLineElement(elementList)
    const { selectElement } = useSelectElement(elementList, dragElement)
    const { scaleElement, scaleMultiElement } = useScaleElement(elementList, alignmentLines)
    const { rotateElement } = useRotateElement(elementList, viewportRef)

    const { selectAllElement } = useSelectAllElement()
    const { deleteAllElements } = useDeleteElement()
    const { pasteElement } = useCopyAndPasteElement()
    const { enterScreening } = useScreening()
    const { updateSlideIndex } = useSlideHandler()

    // 点击画布的空白区域：清空焦点元素、设置画布焦点、清除文字选区
    const handleClickBlankArea = (e: MouseEvent) => {
      store.commit(MutationTypes.SET_ACTIVE_ELEMENT_ID_LIST, [])
      if (!ctrlOrShiftKeyActive.value) updateMouseSelection(e)
      if (!editorAreaFocus.value) store.commit(MutationTypes.SET_EDITORAREA_FOCUS, true)
      removeAllRanges()
    }

    // 移除画布编辑区域焦点
    const removeEditorAreaFocus = () => {
      if (editorAreaFocus.value) store.commit(MutationTypes.SET_EDITORAREA_FOCUS, false)
    }

    // 滚动鼠标
    const { scaleCanvas } = useScaleCanvas()
    const throttleScaleCanvas = throttle(scaleCanvas, 100, { leading: true, trailing: false })
    const throttleUpdateSlideIndex = throttle(updateSlideIndex, 300, { leading: true, trailing: false })

    const handleMousewheelCanvas = (e: WheelEvent) => {

      // 按住Ctrl键时：缩放画布
      if (ctrlKeyState.value) {
        e.preventDefault()
        if (e.deltaY > 0) throttleScaleCanvas('-')
        else if (e.deltaY < 0) throttleScaleCanvas('+')
      }
      // 上下翻页
      else {
        let flag = false
        if (e.target) {
          for (let i = 0; i < (e as any).path.length; i++) {
            if (((e as any).path[i] as HTMLCanvasElement).className == 'knowledgePoints' && ((e as any).path[i] as HTMLCanvasElement).scrollHeight > ((e as any).path[i] as HTMLCanvasElement).clientHeight) {
              flag = true
              return
            }
          }
        }
        if (flag) {
          return
        }else {
          e.preventDefault()
        }
        if (e.deltaY > 0) throttleUpdateSlideIndex(KEYS.DOWN)
        else if (e.deltaY < 0) throttleUpdateSlideIndex(KEYS.UP)
      }
    }

    // 开关网格线
    const showGridLines = computed(() => store.state.showGridLines)
    const toggleGridLines = () => {
      store.commit(MutationTypes.SET_GRID_LINES_STATE, !showGridLines.value)
    }

    // 在鼠标绘制的范围插入元素
    const creatingElement = computed(() => store.state.creatingElement)
    const { insertElementFromCreateSelection } = useInsertFromCreateSelection(viewportRef)

    const contextmenus = (): ContextmenuItem[] => {
      return [
        {
          text: '粘贴',
          subText: 'Ctrl + V',
          handler: pasteElement,
        },
        {
          text: '全选',
          subText: 'Ctrl + A',
          handler: selectAllElement,
        },
        {
          text: '网格线',
          subText: showGridLines.value ? '√' : '',
          handler: toggleGridLines,
        },
        {
          text: '重置当前页',
          handler: deleteAllElements,
        },
        { divider: true },
        {
          text: '从当前页演示',
          subText: 'Ctrl+F',
          handler: enterScreening,
        },
      ]
    }

    provide('slideScale', canvasScale)

    // 独播
    const mitter = getCurrentInstance()?.appContext.config.globalProperties.mitter
    onMounted(() => {
      mitter.on('onlyPlayVideo', (e: any) => {
        elementList.value.forEach((item) => {
          if (item.type === 'video' && item.id !== e) {
            nextTick(() => {
              // const videoo = (document.getElementById( `video${e}_html5_api`)as HTMLInputElement)
              const videoo = <HTMLVideoElement>document.getElementById(`video${item.id + sessionStorage.getItem('player_history'+ '_' + item.id)}_html5_api`)
              if(videoo){
                videoo.pause()
              }
            })
          }
        })
      })
      
      mitter.on('onlyPlayAudio', (e: any) => {
        elementList.value.forEach((item, index) => {
          if (item.type === 'audio' && index != e) {
            nextTick(() => {
              // const videoo = (document.getElementById( `video${e}_html5_api`)as HTMLInputElement)
              const audio0 = <HTMLVideoElement>document.getElementsByClassName('audioPPT')[index]
              if(audio0){
                audio0.pause()
              }
            })
          }
        })
      })
    })

    return {
      elementList,
      activeElementIdList,
      handleElementId,
      activeGroupElementId,
      canvasRef,
      viewportRef,
      viewportStyles,
      canvasScale,
      mouseSelectionState,
      handleClickBlankArea,
      removeEditorAreaFocus,
      currentSlide,
      creatingElement,
      insertElementFromCreateSelection,
      alignmentLines,
      selectElement,
      rotateElement,
      scaleElement,
      dragLineElement,
      scaleMultiElement,
      handleMousewheelCanvas,
      contextmenus,
    }
  },
})
