
import { computed, defineComponent, ref, watch } from 'vue'
import { MutationTypes, useStore } from '@/store'
import { PPTShapeElement, ShapeGradient } from '@/types/slides'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

import ElementOpacity from '../common/ElementOpacity.vue'
import ElementOutline from '../common/ElementOutline.vue'
import ElementShadow from '../common/ElementShadow.vue'
import ElementFlip from '../common/ElementFlip.vue'
import ColorButton from '../common/ColorButton.vue'

export default defineComponent({
  name: 'shape-style-panel',
  components: {
    ElementOpacity,
    ElementOutline,
    ElementShadow,
    ElementFlip,
    ColorButton,
  },
  setup() {
    const store = useStore()
    const handleElement = computed<PPTShapeElement>(() => store.getters.handleElement)

    const fill = ref<string>()
    const gradient = ref<ShapeGradient>()
    const fillType = ref('fill')

    watch(handleElement, () => {
      if (!handleElement.value || handleElement.value.type !== 'shape') return
      fill.value = handleElement.value.fill || '#000'

      gradient.value = handleElement.value.gradient || { type: 'linear', rotate: 0, color: [fill.value, '#fff'] }

      fillType.value = handleElement.value.gradient ? 'gradient' : 'fill'
    }, { deep: true, immediate: true })

    const { addHistorySnapshot } = useHistorySnapshot()

    // 设置填充类型：渐变、纯色
    const updateFillType = (type: 'gradient' | 'fill') => {
      if (type === 'fill') {
        store.commit(MutationTypes.REMOVE_ELEMENT_PROPS, {
          id: handleElement.value.id,
          propName: 'gradient',
        })
      }
      else {
        const props = { gradient: gradient.value }
        store.commit(MutationTypes.UPDATE_ELEMENT, { id: handleElement.value.id, props })
      }
      addHistorySnapshot()
    }

    // 设置渐变填充
    const updateGradient = (gradientProps: Partial<ShapeGradient>) => {
      const props = { gradient: { ...gradient.value, ...gradientProps } }
      store.commit(MutationTypes.UPDATE_ELEMENT, { id: handleElement.value.id, props })
      addHistorySnapshot()
    }

    // 设置填充色
    const updateFill = (value: string) => {
      const props = { fill: value }
      store.commit(MutationTypes.UPDATE_ELEMENT, { id: handleElement.value.id, props })
      addHistorySnapshot()
    }

    return {
      fill,
      gradient,
      fillType,
      updateFillType,
      updateFill,
      updateGradient,
    }
  },
})
