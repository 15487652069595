
import { defineComponent, ref } from 'vue'

import useGlobalHotkey from '@/hooks/useGlobalHotkey'
import usePasteEvent from '@/hooks/usePasteEvent'

import Thumbnails from './Thumbnails/index.vue'
import Remark from './Remark/index.vue'
import Screen from '../Screen/index.vue'

export default defineComponent({
  name: 'editor',
  components: {
    Screen,
    Thumbnails,
    Remark
  },
  setup() {
    const remarkHeight = ref(40)

    useGlobalHotkey()
    usePasteEvent()

    return {
      remarkHeight,
    }
  },
})
