
import { computed, defineComponent } from 'vue'
import { MutationTypes, useStore } from '@/store'
import { Slide, SlideBackground, SlideTheme } from '@/types/slides'
import { PRESET_THEMES } from '@/configs/theme'
import { WEB_FONTS } from '@/configs/font'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

import ColorButton from './common/ColorButton.vue'
import { getImageDataURL } from '@/utils/image'

const themes = PRESET_THEMES
const webFonts = WEB_FONTS

export default defineComponent({
  name: 'slide-style-panel',
  components: {
    ColorButton,
  },
  setup() {
    const store = useStore()
    const slides = computed(() => store.state.slides)
    const theme = computed(() => store.state.theme)
    const availableFonts = computed(() => store.state.availableFonts)
    const viewportRatio = computed(() => store.state.viewportRatio)
    const currentSlide = computed<Slide>(() => store.getters.currentSlide)

    const background = computed(() => {
      if (!currentSlide.value.background) {
        return {
          type: 'solid',
          value: '#fff',
        } as SlideBackground
      }
      return currentSlide.value.background
    })

    const { addHistorySnapshot } = useHistorySnapshot()

    // 设置背景模式：纯色、图片、渐变色
    const updateBackgroundType = (type: 'solid' | 'image' | 'gradient') => {
      if (type === 'solid') {
        const newBackground: SlideBackground = {
          ...background.value,
          type: 'solid',
          color: background.value.color || '#fff',
        }
        store.commit(MutationTypes.UPDATE_SLIDE, { background: newBackground })
      }
      else if (type === 'image') {
        const newBackground: SlideBackground = {
          ...background.value,
          type: 'image',
          image: background.value.image || '',
          imageSize: background.value.imageSize || 'cover',
        }
        store.commit(MutationTypes.UPDATE_SLIDE, { background: newBackground })
      }
      else {
        const newBackground: SlideBackground = {
          ...background.value,
          type: 'gradient',
          gradientType: background.value.gradientType || 'linear',
          gradientColor: background.value.gradientColor || ['#fff', '#fff'],
          gradientRotate: background.value.gradientRotate || 0,
        }
        store.commit(MutationTypes.UPDATE_SLIDE, { background: newBackground })
      }
      addHistorySnapshot()
    }

    // 设置背景图片
    const updateBackground = (props: Partial<SlideBackground>) => {
      store.commit(MutationTypes.UPDATE_SLIDE, { background: { ...background.value, ...props } })
      addHistorySnapshot()
    }

    // 上传背景图片
    const uploadBackgroundImage = (files: File[]) => {
      const imageFile = files[0]
      if (!imageFile) return
      getImageDataURL(imageFile).then(dataURL => updateBackground({ image: dataURL }))
    }

    // 应用当前页背景到全部页面
    const applyBackgroundAllSlide = () => {
      const newSlides = slides.value.map(slide => {
        return {
          ...slide,
          background: currentSlide.value.background,
        }
      })
      store.commit(MutationTypes.SET_SLIDES, newSlides)
      addHistorySnapshot()
    }

    // 设置主题
    const updateTheme = (themeProps: Partial<SlideTheme>) => {
      store.commit(MutationTypes.SET_THEME, themeProps)
    }

    // 将当前主题应用到全部页面
    const applyThemeAllSlide = () => {
      const newSlides: Slide[] = JSON.parse(JSON.stringify(slides.value))
      const { themeColor, backgroundColor, fontColor } = theme.value

      for (const slide of newSlides) {
        if (!slide.background || slide.background.type !== 'image') {
          slide.background = {
            ...slide.background,
            type: 'solid',
            color: backgroundColor
          }
        }

        const elements = slide.elements
        for (const el of elements) {
          if (el.type === 'shape') el.fill = themeColor
          else if (el.type === 'line') el.color = themeColor
          else if (el.type === 'text') {
            el.fontColor = fontColor
          }
          else if (el.type === 'table') {
            if (el.theme) el.theme.color = themeColor
          }
          else if (el.type === 'chart') {
            el.themeColor = themeColor
            el.gridColor = fontColor
          }
        }
      }
      store.commit(MutationTypes.SET_SLIDES, newSlides)
      addHistorySnapshot()
    }

    // 设置画布尺寸（宽高比例）
    const updateViewportRatio = (value: number) => {
      store.commit(MutationTypes.SET_VIEWPORT_RATIO, value)
    }

    return {
      availableFonts,
      background,
      updateBackgroundType,
      updateBackground,
      uploadBackgroundImage,
      applyBackgroundAllSlide,
      themes,
      theme,
      webFonts,
      updateTheme,
      applyThemeAllSlide,
      viewportRatio,
      updateViewportRatio,
    }
  },
})
