
import { computed, PropType, defineComponent } from 'vue'
import { useStore } from '@/store'
import { AlignmentLineAxis } from '@/types/edit'

export default defineComponent({
  name: 'alignment-line',
  props: {
    type: {
      type: String as PropType<'vertical' | 'horizontal'>,
      required: true,
    },
    axis: {
      type: Object as PropType<AlignmentLineAxis>,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const canvasScale = computed(() => store.state.canvasScale)

    // 吸附对齐线的位置
    const left = computed(() => props.axis.x * canvasScale.value + 'px')
    const top = computed(() => props.axis.y * canvasScale.value + 'px')

    // 吸附对齐线的长度
    const sizeStyle = computed(() => {
      if (props.type === 'vertical') return { height: props.length * canvasScale.value + 'px' }
      return { width: props.length * canvasScale.value + 'px' }
    })

    return {
      left,
      top,
      sizeStyle,
    }
  },
})
