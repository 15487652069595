
// 打点
import '@/assets/video-markers/videojs-markers.js'
import '@/assets/video-markers/videojs.markers.css'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

import { computed, defineComponent, PropType, reactive, onMounted, onUnmounted, getCurrentInstance, nextTick, ref } from 'vue'
import { PPTVideoElement } from '@/types/slides'
import useElementShadow from '@/views/components/element/hooks/useElementShadow'
import useElementFlip from '@/views/components/element/hooks/useElementFlip'
import useClipImage from './useClipImage'
import useFilter from './useFilter'

import VideoOutline from './VideoOutline/index.vue'

export default defineComponent({
  name: 'base-element-image',
  components: {
    VideoOutline
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTVideoElement>,
      required: true,
    },
  },
  setup(props) {
    const shadow = computed(() => props.elementInfo.shadow)
    const { shadowStyle } = useElementShadow(shadow)

    const flip = computed(() => props.elementInfo.flip)
    const { flipStyle } = useElementFlip(flip)
    
    const clip = computed(() => props.elementInfo.clip)
    const { clipShape, imgPosition } = useClipImage(clip)

    const filters = computed(() => props.elementInfo.filters)
    const { filter } = useFilter(filters)

    // 打点slide-content
    const mitter = getCurrentInstance()?.appContext.config.globalProperties.mitter
    let playerSlideshow: any = reactive({})
    const videoJs = reactive({
      options: {
        controls: true, // 必填，显示各控制组件
        // autoplay: true, // 是否自动播放
        // preload: 'auto', // 预加载
        // width: '450px',
        // 'max-height': '300px',
        // height: 'auto',
        loop: false, // 是否循环播放
        playbackRates: [0.5, 1, 1.5, 2], // 是否显示倍速
        controlBar: {
          currentTimeDisplay: true,
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: true,
          volumePanel: {
            inline: false // 将音量横向改为纵向
          }
        },
        controlslist: 'nodownload',
        disablePictureInPicture: true
      },
    })  
    let pointIndex: any = ref(0)
    onMounted(() => {
      nextTick(() => {
        playerSlideshow = videojs('video' + props.elementInfo.id, videoJs.options)
        playerSlideshow.markers({
          markerStyle: { // 标记样式
            'width': '8px',
            // 'border-radius': '0%',
            'background-color': 'orange'
          },
          markerTip: {
            display: true, // 不显示标记的tip
            text: function(marker: any) {
              return marker.text ? marker.text : ' '
            },
          },
          markers: props.elementInfo.marker ? props.elementInfo.marker : [],
          onMarkerReached: (marker: any, index: any) => {
            pointIndex.value = index
          }
        })
        playerSlideshow.on('play', () => {
          mitter.emit('onlyPlaySlideshow', props.elementInfo.id)
        }) 
      })
    })

    // if (props.elementInfo.actionPointKnowledges && props.elementInfo.actionPointKnowledges.length > 0) {
    //   knowledge = props.elementInfo.actionPointKnowledges[0]
    // }
    const changeCurrentTime = (index: any) => {
      playerSlideshow.pause()
      playerSlideshow.currentTime(props.elementInfo.marker[index].time)
      playerSlideshow.play()
      pointIndex.value = index
      
      // mitter.emit('changePointIndex', index)
      // if (props.elementInfo.actionPointKnowledges && props.elementInfo.actionPointKnowledges.length > 0) {
      //   knowledge = props.elementInfo.actionPointKnowledges[index]
      // }
    }

    const s_to_hs = (s: any) => {
      s = parseInt(s)
      //计算分钟
      //算法：将秒数除以60，然后下舍入，既得到分钟数
      let h
      h  =   Math.floor(s/60)
      //计算秒
      //算法：取得秒%60的余数，既得到秒数
      s  =   s%60
      //将变量转换为字符串
      h = h.toString()
      s = s.toString()
      //如果只有一位数，前面增加一个0
      // h = (h.length == 1) ? '0' + h : h
      s = (s.length == 1) ? '0' + s : s
      return h+':'+s
    }

    onUnmounted(() => {
      playerSlideshow.dispose()
    })
    return {
      imgPosition,
      filter,
      flipStyle,
      shadowStyle,
      clipShape,
      changeCurrentTime,
      pointIndex,
      s_to_hs
    }
  },
})
