
import { defineComponent, PropType, computed } from 'vue'
import { useStore } from '@/store'
import { ElementTypes, PPTElement, Slide } from '@/types/slides'
import { OperateLineHandler, OperateResizeHandler } from '@/types/edit'
// 修改
import AudioElementOperate from './AudioElementOperate.vue'
import VideoElementOperate from './VideoElementOperate.vue'
import ImageElementOperate from './ImageElementOperate.vue'
import TextElementOperate from './TextElementOperate.vue'
import ShapeElementOperate from './ShapeElementOperate.vue'
import LineElementOperate from './LineElementOperate.vue'
import ChartElementOperate from './ChartElementOperate.vue'
import TableElementOperate from './TableElementOperate.vue'

export default defineComponent({
  name: 'operate',
  props: {
    elementInfo: {
      type: Object as PropType<PPTElement>,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    isActiveGroupElement: {
      type: Boolean,
      required: true,
    },
    isMultiSelect: {
      type: Boolean,
      required: true,
    },
    rotateElement: {
      type: Function as PropType<(element: PPTElement) => void>,
      required: true,
    },
    scaleElement: {
      type: Function as PropType<(e: MouseEvent, element: PPTElement, command: OperateResizeHandler) => void>,
      required: true,
    },
    dragLineElement: {
      type: Function as PropType<(e: MouseEvent, element: PPTElement, command: OperateLineHandler) => void>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const canvasScale = computed(() => store.state.canvasScale)
    const toolbarState = computed(() => store.state.toolbarState)
    const currentSlide = computed<Slide>(() => store.getters.currentSlide)

    const currentOperateComponent = computed(() => {
      const elementTypeMap = {
        [ElementTypes.AUDIO]: AudioElementOperate,
        [ElementTypes.VIDEO]: VideoElementOperate,
        [ElementTypes.IMAGE]: ImageElementOperate,
        [ElementTypes.TEXT]: TextElementOperate,
        [ElementTypes.SHAPE]: ShapeElementOperate,
        [ElementTypes.LINE]: LineElementOperate,
        [ElementTypes.CHART]: ChartElementOperate,
        [ElementTypes.TABLE]: TableElementOperate,
      }
      return elementTypeMap[props.elementInfo.type] || null
    })

    const elementIndexInAnimation = computed(() => {
      const animations = currentSlide.value.animations || []
      return animations.findIndex(animation => animation.elId === props.elementInfo.id)
    })

    return {
      currentOperateComponent,
      canvasScale,
      toolbarState,
      elementIndexInAnimation,
    }
  },
})
