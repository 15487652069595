import { Slide } from '@/types/slides'

const uuid = 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
  const r = Math.random() * 16 | 0
  const v = c === 'x' ? r : (r & 0x3 | 0x8)
  return v.toString(16)
})
export const slides: Slide[] = [
  {
    id: uuid,
    elements: [],
    background: {
      type: 'solid',
      color: '#ffffff',
    },
  }
]