
import { computed, defineComponent, onMounted, ref, getCurrentInstance, nextTick, reactive, onUnmounted } from 'vue'
import { MutationTypes, useStore } from '@/store'
import useScreening from '@/hooks/useScreening'
import useSlideHandler from '@/hooks/useSlideHandler'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'
import ResourcePool from './components/resourcePool.vue'

import HotkeyDoc from './HotkeyDoc.vue'
import ExportDialog from './ExportDialog.vue'

import axios from 'axios'
import useCreateElement from '@/hooks/useCreateElement'

export default defineComponent({
  name: 'editor-header',
  components: {
    HotkeyDoc,
    ExportDialog,
    ResourcePool
  },
  setup() {
    const store = useStore()
    
    const slides = computed(() => store.state.slides)

    const { enterScreening, enterScreeningFromStart } = useScreening()
    const { createSlide, deleteSlide, resetSlides } = useSlideHandler()
    const { redo, undo } = useHistorySnapshot()

    const showGridLines = computed(() => store.state.showGridLines)
    const toggleGridLines = () => {
      store.commit(MutationTypes.SET_GRID_LINES_STATE, !showGridLines.value)
    }

    const hotkeyDrawerVisible = ref(false)
    const exportDialogVisible = ref(false)

    const message = getCurrentInstance()?.appContext.config.globalProperties.message
    const baseUrl = getCurrentInstance()?.appContext.config.globalProperties.baseUrl
    const { createAudioElement, createResourceVideoElement, createImageElement} = useCreateElement()

    const poll = ref(false)
    const resources = ref(false)
    const openModal = ref(false)
    const knowledgeName = ref('')
    const name = ref('')
    const hourId = ref('')   
    const courseName = ref('')   
    const type = ref('') 
    const token = ref('')
    const editPPtId = ref('')
    const ipSrc = ref('')
    const isF5 = ref(true)
    // 授课
    const teachCourseName = ref('')
    const teachTextbookVersion = ref('')
    const teachSerialNumber = ref('')
    const teachCurrentClass = ref('')

    let lastSavedTime: any = ref('')
    lastSavedTime.value = new Date().toLocaleTimeString()

    ipSrc.value = sessionStorage.getItem('ipSrc') ? JSON.stringify(sessionStorage.getItem('ipSrc')).substring(1,JSON.stringify(sessionStorage.getItem('ipSrc')).length - 1) : ''
    token.value = sessionStorage.getItem('token') ? JSON.stringify(sessionStorage.getItem('token')).substring(1,JSON.stringify(sessionStorage.getItem('token')).length - 1) : ''
    type.value = sessionStorage.getItem('type') ? JSON.stringify(sessionStorage.getItem('type')).substring(1,JSON.stringify(sessionStorage.getItem('type')).length - 1) : ''
    courseName.value = sessionStorage.getItem('courseName') ? JSON.stringify(sessionStorage.getItem('courseName')).substring(1,JSON.stringify(sessionStorage.getItem('courseName')).length - 1) : ''
    hourId.value = sessionStorage.getItem('hourId') ? JSON.stringify(sessionStorage.getItem('hourId')).substring(1,JSON.stringify(sessionStorage.getItem('hourId')).length - 1) : ''
    name.value = sessionStorage.getItem('name') ? JSON.stringify(sessionStorage.getItem('name')).substring(1,JSON.stringify(sessionStorage.getItem('name')).length - 1) : ''
    // knowledgeName.value = sessionStorage.getItem('knowledgeName') ? JSON.stringify(sessionStorage.getItem('knowledgeName')).substring(1,JSON.stringify(sessionStorage.getItem('knowledgeName')).length - 1)  : ''

    teachCourseName.value = sessionStorage.getItem('teachCourseName') ? JSON.stringify(sessionStorage.getItem('teachCourseName')).substring(1,JSON.stringify(sessionStorage.getItem('teachCourseName')).length - 1)  : ''
    teachTextbookVersion.value = sessionStorage.getItem('teachTextbookVersion') ? JSON.stringify(sessionStorage.getItem('teachTextbookVersion')).substring(1,JSON.stringify(sessionStorage.getItem('teachTextbookVersion')).length - 1)  : ''
    teachSerialNumber.value = sessionStorage.getItem('teachSerialNumber') ? JSON.stringify(sessionStorage.getItem('teachSerialNumber')).substring(1,JSON.stringify(sessionStorage.getItem('teachSerialNumber')).length - 1)  : ''
    teachCurrentClass.value = sessionStorage.getItem('teachCurrentClass') ? JSON.stringify(sessionStorage.getItem('teachCurrentClass')).substring(1,JSON.stringify(sessionStorage.getItem('teachCurrentClass')).length - 1)  : ''
    
    if (sessionStorage.getItem('id')) {
      editPPtId.value = sessionStorage.getItem('id') ? JSON.stringify(sessionStorage.getItem('id')) : ''
    }
    if (sessionStorage.getItem('id') && !sessionStorage.getItem('isSlideshow')) {
        axios.get(baseUrl + '/courseResource/detail', { params: {id: sessionStorage.getItem('id')}, headers: {'Authorization': token.value }}).then((res: any) => {
          if (res.data.status === 200) {
            res.data.data && res.data.data.pptTheme && store.commit(MutationTypes.SET_THEME, JSON.parse(res.data.data.pptTheme))
            res.data.data && res.data.data.pptContent && store.commit(MutationTypes.SET_SLIDES, JSON.parse(res.data.data.pptContent))
          } else {
            message.error(res.data.message)
          }
        }).catch((e: any)=>{
          if (e.message.indexOf('403') != '-1') {
            message.error('身份信息已过期、请重新登录!')
          }
        })
    }

    function linstener(event: any) {
        // 安全
        if (event.origin !== 'https://admin.brimfar.online' && event.origin !== 'https://teacher.brimfar.online') {
          return
        }
        // 资源库添加多媒体
        if (event.data.pptType === '资源库多媒体') {
          if (event.data.type === '图片') {
            createImageElement(event.data.src)
          } 
          if (event.data.type === '视频') {
            createSlide()
            setTimeout(() => {
              createResourceVideoElement(event.data.src, event.data.actionPoint, event.data.actionPointKnowledges)
            }, 100)
          }
          if (event.data.type === '音频') {
            createAudioElement(event.data.src)
          }
          poll.value = false
        }
        else if(event.data.pptType === '资源库课件或案例') {
          // 资源库
          sessionStorage.setItem('token', event.data.token)
          sessionStorage.setItem('ResourcesID', event.data.ResourcesID)
          sessionStorage.setItem('ResourcesType', event.data.ResourcesType)
          poll.value = !poll.value
          resources.value = !resources.value
        }
        // 资源库添加习题
        else if(event.data.pptType === '资源库习题') {
          poll.value = false
          store.commit(MutationTypes.SET_CREATING_ELEMENT, {
            type: 'text',
            textType: 'question',
            data: JSON.stringify(event.data),
          })
        }
        // 新增时候带过来的数据
        else if (event.data.pptType === '新增课件或案例' ) {
          sessionStorage.setItem('token', event.data.token)
          sessionStorage.setItem('type', event.data.type)
          sessionStorage.setItem('courseId', event.data.courseId)
          sessionStorage.setItem('courseName', event.data.courseName)
          sessionStorage.setItem('name', event.data.name)
          // sessionStorage.setItem('knowledgeName', event.data.knowledgeName)
          sessionStorage.setItem('ipSrc', event.origin)
        
          ipSrc.value = event.origin
          token.value = event.data.token
          type.value = event.data.type
          courseName.value = event.data.courseName
          name.value = event.data.name
          // knowledgeName.value = event.data.knowledgeName

          const targetWiondow: any = window.opener
          targetWiondow.postMessage('关闭新增页定时器', ipSrc.value + '/#/workbench/resourcePool')
          // 字典
          // axios.get(baseUrl + '/dict/all', {headers: {'Authorization': token.value }}).then( res => {
          //   sessionStorage.setItem('dict', JSON.stringify(res.data.data))
          // })
        }
        // 编辑
        else if (event.data.pptType === '编辑课件或案例' ) {
          sessionStorage.setItem('ipSrc', event.origin)
          sessionStorage.setItem('id', event.data.id)
          sessionStorage.setItem('token', event.data.token)
          sessionStorage.setItem('type', event.data.type)
          sessionStorage.setItem('courseId', event.data.courseId)
          sessionStorage.setItem('courseName', event.data.courseName)
          sessionStorage.setItem('name', event.data.name)
          // sessionStorage.setItem('knowledgeName', event.data.knowledgeName)

          ipSrc.value = event.origin
          editPPtId.value = event.data.id
          token.value = event.data.token
          type.value = event.data.type
          courseName.value = event.data.courseName
          name.value = event.data.name
          // knowledgeName.value = event.data.knowledgeName
          // 字典
          // axios.get(baseUrl + '/dict/all', {headers: {'Authorization': token.value }}).then( res => {
          //   sessionStorage.setItem('dict', JSON.stringify(res.data.data))
          // })
          const targetWiondow: any = window.opener
          targetWiondow.postMessage('关闭编辑页定时器', ipSrc.value + '/#/workbench/resourcePool')
          axios.get(baseUrl + '/courseResource/detail', { params: {id: sessionStorage.getItem('id')}, headers: {'Authorization': token.value }}).then( res => {
            if (res.data.status === 200) {
              res.data.data && res.data.data.pptTheme && store.commit(MutationTypes.SET_THEME, JSON.parse(res.data.data.pptTheme))
              res.data.data && res.data.data.pptContent && store.commit(MutationTypes.SET_SLIDES, JSON.parse(res.data.data.pptContent))
            } else{
              message.error(res.data.message)
            }
          }).catch((e: any)=>{
            if (e.message.indexOf('403') != '-1') {
              message.error('身份信息已过期、请重新登录!')
            }
          })
        }
        // 授课
        else if(event.data.pptType === '授课') {
          sessionStorage.setItem('ipSrc', event.origin)
          sessionStorage.setItem('id', event.data.id)
          sessionStorage.setItem('token', event.data.token)
          sessionStorage.setItem('hourId', event.data.hourId)
          sessionStorage.setItem('teachCourseName', event.data.courseName)
          sessionStorage.setItem('teachTextbookVersion', event.data.textbookVersion)
          sessionStorage.setItem('teachSerialNumber', event.data.serialNumber)
          sessionStorage.setItem('teachCurrentClass', event.data.currentClass)
  
          hourId.value = event.data.hourId
          ipSrc.value = event.origin
          editPPtId.value = event.data.id
          token.value = event.data.token
          teachCourseName.value  = event.data.courseName
          teachTextbookVersion.value  = event.data.textbookVersion
          teachSerialNumber.value  = event.data.serialNumber
          teachCurrentClass.value  = event.data.currentClass
          
          axios.get(baseUrl + '/courseResource/detail', { params: {id: event.data.id}, headers: {'Authorization': token.value }}).then( res => {
            if (res.data.status === 200) {
              res.data.data && res.data.data.pptContent && store.commit(MutationTypes.SET_SLIDES, JSON.parse(res.data.data.pptContent))
            } else{
              message.error(res.data.message)
            }
          }).catch((e: any)=>{
            if (e.message.indexOf('403') != '-1') {
              message.error('身份信息已过期、请重新登录!')
            }
          })
          const targetWiondow: any = window.opener
          targetWiondow.postMessage('关闭授课的定时器', ipSrc.value + '/#/home/courseDetails/' + event.data.id)
        }
      }
    
    onUnmounted(() => {
      window.removeEventListener("message",linstener)
    })

    onMounted(() => {
      window.addEventListener('message',linstener)
      
      // 自动保存
      setInterval(() => {
        const config = {
          headers: { 'Authorization': token.value }
        }
        const pptContent = JSON.stringify(slides.value)
        if(!pptContent){
          return
        }
        if(!hourId.value) {
          if (sessionStorage.getItem('id')) {
            const param = {
              'id': sessionStorage.getItem('id'),
              'type': type.value,
              'courseId': sessionStorage.getItem('courseId'),
              'courseName': courseName.value,
              'name': name.value,
              // 'knowledgeName': knowledgeName.value,
              'pptTheme': JSON.stringify(store.state.theme),
              pptContent
            }
            axios.post(baseUrl + '/courseResource/edit', param, config).then(res => {
              if (res.data.status === 200) {
                // message.success('自动保存成功!')
                // isF5.value = false
                // openModal.value = false
                // sessionStorage.clear()
                // const targetWiondow: any = window.opener
                // targetWiondow.postMessage('编辑成功', ipSrc.value + '/#/workbench/resourcePool')
                lastSavedTime.value =  new Date().toLocaleTimeString()        
              } else {
                message.error(res.data.message)
              }
            }).catch((e: any)=>{
              if (e.message.indexOf('403') != '-1') {
                message.error('身份信息已过期、请重新登录!')
              }
            })
          }
        }
      }, 60000)
    })
    window.addEventListener('beforeunload', (e) => {
      if(isF5.value) {
        if (e) {
          e.returnValue = '关闭提示'
        }
        sessionStorage.setItem('isSlideshow', '')
        return '关闭提示'
      }else {
        if (e) {
          e.returnValue
        }
      }
      
    }, false)

    const saveData = () => {
      const config = {
        headers: { 'Authorization': token.value }
      }
      
      if(hourId.value) {
        const param = {
          'id': hourId.value
        }
        axios.post(baseUrl + '/curriculumhour/finish', param, config).then(res => {
          if (res.data.status === 200) {
            message.success('授课完成!')
            isF5.value = false
            openModal.value = false
            sessionStorage.clear()
            const targetWiondow: any = window.opener
            targetWiondow.postMessage('授课完成', ipSrc.value + '/#/home/index')
            setTimeout(() => {
              window.close()
            }, 1000)
          } else {
            message.error(res.data.message)
          }
        }).catch((e: any)=>{
          if (e.message.indexOf('403') != '-1') {
            message.error('身份信息已过期、请重新登录!')
          }
        })
      }else{
        const pptContent = JSON.stringify(slides.value)
        if (sessionStorage.getItem('id')) {
          const param = {
            'id': sessionStorage.getItem('id'),
            'type': type.value,
            'courseId': sessionStorage.getItem('courseId'),
            'courseName': courseName.value,
            'name': name.value,
            // 'knowledgeName': knowledgeName.value,
            'pptTheme': JSON.stringify(store.state.theme),
            pptContent
          }
          axios.post(baseUrl + '/courseResource/edit', param, config).then(res => {
            if (res.data.status === 200) {
              message.success('数据编辑成功!')
              isF5.value = false
              openModal.value = false
              // sessionStorage.clear()
              const targetWiondow: any = window.opener
              targetWiondow.postMessage('编辑成功', ipSrc.value + '/#/workbench/resourcePool')
              lastSavedTime.value =  new Date().toLocaleTimeString()     
            } else {
              message.error(res.data.message)
            }
          }).catch((e: any)=>{
            if (e.message.indexOf('403') != '-1') {
              message.error('身份信息已过期、请重新登录!')
            }
          })
        }
        else {
          const param = {
            'type': type.value,
            'courseId': sessionStorage.getItem('courseId'),
            'courseName': courseName.value,
            'name': name.value,
            // 'knowledgeName': knowledgeName.value,
            pptContent
          }
          
          axios.post(baseUrl + '/courseResource/add', param, config).then(res => {
            if (res.data.status === 200) {
              message.success('数据新增成功!')
              isF5.value = false
              openModal.value = false
              sessionStorage.clear()
              const targetWiondow: any = window.opener
              targetWiondow.postMessage('新增成功', ipSrc.value + '/#/workbench/resourcePool')
              setTimeout(() => {
                window.close() 
              }, 1000)
            } else {
              message.error(res.data.message)
            }
          }).catch((e: any)=>{
            if (e.message.indexOf('403') != '-1') {
              message.error('身份信息已过期、请重新登录!')
            }
          })
        }
      }
    }

    const openZiYuan = () => {
      poll.value = !poll.value
      resources.value = false
      // const iframe =  <HTMLVideoElement>document.getElementById('iframe')
    }

    return {
      enterScreening,
      enterScreeningFromStart,
      createSlide,
      deleteSlide,
      redo,
      undo,
      toggleGridLines,
      showGridLines,
      resetSlides,
      hotkeyDrawerVisible,
      exportDialogVisible,
      poll,
      resources,
      saveData,
      openModal,
      courseName,
      name,
      // knowledgeName,
      openZiYuan,
      editPPtId,
      ipSrc,
      hourId,
      lastSavedTime,
      teachCourseName,
      teachTextbookVersion,
      teachSerialNumber,
      teachCurrentClass
    }
  },
})
