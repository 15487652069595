
// import 'videojs-markers'
import '@/assets/video-markers/videojs-markers.js'
import '@/assets/video-markers/videojs.markers.css'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

import { computed, defineComponent, ref, reactive, PropType, onMounted, nextTick, inject, getCurrentInstance, onUnmounted, watch, HtmlHTMLAttributes  } from 'vue'
import { MutationTypes, useStore } from '@/store'
// 修改
import { VideoElementClip, PPTVideoElement } from '@/types/slides'
import { VideoClipedEmitData } from '@/types/edit'
import { ContextmenuItem } from '@/components/Contextmenu/types'
import useElementShadow from '@/views/components/element/hooks/useElementShadow'
import useElementFlip from '@/views/components/element/hooks/useElementFlip'
import useClipVideo from './useClipImage'
import useFilter from './useFilter'

// import VideoManagement from '@/components/VideoManagement.vue'
import VideoOutline from './VideoOutline/index.vue'
// import ImageClipHandler from './ImageClipHandler.vue'

export default defineComponent({
  name: 'editable-element-video',
  components: {
    // VideoManagement,
    VideoOutline,
    // ImageClipHandler,
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTVideoElement>,
      required: true,
    },
    selectElement: {
      type: Function as PropType<(e: MouseEvent, element: PPTVideoElement, canMove?: boolean) => void>,
      required: true,
    },
    contextmenus: {
      type: Function as PropType<() => ContextmenuItem[]>,
    },
  },
  setup(props) {
    const store = useStore()
    const clipingImageElementId = computed(() => store.state.clipingImageElementId)
    const isCliping = computed(() => clipingImageElementId.value === props.elementInfo.id)

    const shadow = computed(() => props.elementInfo.shadow)
    const { shadowStyle } = useElementShadow(shadow)

    const flip = computed(() => props.elementInfo.flip)
    const { flipStyle } = useElementFlip(flip)

    const clip = computed(() => props.elementInfo.clip)
    const { clipShape, imgPosition } = useClipVideo(clip)

    const filters = computed(() => props.elementInfo.filters)
    const { filter } = useFilter(filters)
    // const viewportWrapperWidth: any = document.getElementsByClassName('viewport-wrapper')[0].clientWidth * 0.2
    
    const history: any = sessionStorage.getItem('player_history' + '_' + props.elementInfo.id) ? sessionStorage.getItem('player_history' + '_' + props.elementInfo.id) : 0 
    if (!sessionStorage.getItem('player_history' + '_' + props.elementInfo.id)) {
      sessionStorage.setItem('player_history' + '_' + props.elementInfo.id, '0')
    }
    const handleSelectElement = (e: MouseEvent) => {
      if (props.elementInfo.lock || props.elementInfo.videoSource) return
      e.stopPropagation()
      props.selectElement(e, props.elementInfo)
    }

    const handleClip = (data: VideoClipedEmitData) => {
      store.commit(MutationTypes.SET_CLIPING_IMAGE_ELEMENT_ID, '')
      
      if (!data) return

      const { range, position } = data
      const originClip: VideoElementClip = props.elementInfo.clip || { shape: 'rect', range: [[0, 0], [100, 100]] }
      
      const _props = {
        clip: { ...originClip, range },
        left: props.elementInfo.left + position.left,
        top: props.elementInfo.top + position.top,
        width: props.elementInfo.width + position.width,
        height: props.elementInfo.height + position.height,
      }
      store.commit(MutationTypes.UPDATE_ELEMENT, { id: props.elementInfo.id, props: _props })
    }
    
    // const video = ref(null)

    let player:any = reactive({})
    const videoJs = reactive({
      options: {
        controls: true, // 必填，显示各控制组件
        // autoplay: true, // 是否自动播放
        // preload: 'auto', // 预加载
        // width: '450px',
        // 'max-height': '300px',
        // height: 'auto',
        loop: false, // 是否循环播放
        playbackRates: [0.5, 1, 1.5, 2], // 是否显示倍速
        controlBar: {
          currentTimeDisplay: true,
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: true,
          volumePanel: {
            inline: false // 将音量横向改为纵向
          }
        },
        controlslist: 'nodownload',
        disablePictureInPicture: true
      },
      // markersData: [],
      // videoTimeLength: ''
    }) 

    const mitter = getCurrentInstance()?.appContext.config.globalProperties.mitter
    const message = getCurrentInstance()?.appContext.config.globalProperties.message
    let pointIndex: any = ref(0)

    onMounted(() => {
      player = videojs('video' + props.elementInfo.id + history, videoJs.options)
      player.markers({
        markerStyle: { // 标记样式
          'width': '8px',
          // 'border-radius': '0%',
          'background-color': 'orange'
        },
        markerTip: {
          display: true, // 不显示标记的tip
          text: function(marker: any) {
            return marker.text ? marker.text : ' '
          },
        },
        markers: props.elementInfo.marker ? props.elementInfo.marker : [],
        onMarkerReached: (marker: any, index: any) => {
          pointIndex.value = index
          mitter.emit('changePointIndex' + props.elementInfo.id, index)
        }
      })
      watch(player, () => {
        // player.on('onloadedmetadata', () => {
        //   mitter.emit(`videoDuration?${props.elementInfo.id}`, player.duration()) 
        // })
        player.on('play', () => {
          mitter.emit('onlyPlayVideo', props.elementInfo.id)
        })
        // 打点
        const setNode = () => {
          nextTick(() => {
            let nodeCurrentTime = Number(player.currentTime().toFixed(2)) > 0.01 ? (Number(player.currentTime().toFixed(2)) - 0.01).toFixed(2) : 0

            const isExist = player.markers.getMarkers().some((item: any) => {
              return item.time == nodeCurrentTime
            })
            
            if (!isExist) {
              player.markers.add([
                {
                  time: nodeCurrentTime
                }
              ])
              message.success('新增节点成功!')
            } 
            else {
              message.warning('此处已有节点!')
            }
            mitter.emit(`getData?${props.elementInfo.id}` + history, player.markers.getMarkers())
          })
        }
        // 打点
        mitter.on(`setNodeVideo?${props.elementInfo.id}` + history, () => {
          setNode()
        })
        // 时间
        mitter.on(`setChangeTime?${props.elementInfo.id}` + history, (e: any) => {
          // if (props.elementInfo && props.elementInfo.videoDuration) {
          
          if (Number(e[e.length - 1]) > Number(player.duration())) {
            message.warning(`视频时长为${player.duration().toFixed(2) - 0.01}!请重新设置!`) 
            // message.warning(`超过视频总长度!请重新设置!`) 
            mitter.emit(`getData?${props.elementInfo.id}` + history, player.markers.getMarkers())
            return
          }
          const isRepeat = player.markers.getMarkers().some((item: any) => {
            return item.time === e[e.length - 1]
          })
          if (!isRepeat) {
            player.markers.getMarkers()[e[0]].time = e[e.length - 1]
            player.markers.updateTime()
            // message.success('时间更新成功!')
          } 
          else {
            message.warning('此时间段已有节点、请重新设置!')
          }
          mitter.emit(`getData?${props.elementInfo.id}` + history, player.markers.getMarkers())
        })
        // 内容
        mitter.on(`setChangeContent?${props.elementInfo.id}` + history, (e: any) => {
          player.markers.getMarkers()[e[0]].text = e[e.length - 1]
          // message.success('内容更新成功!')
        })
        // 跳转
        mitter.on(`viewNodeVideo?${props.elementInfo.id}` + history, (e: any) => { 
          player.pause()
          player.currentTime(e)
        })
        // 删除
        mitter.on(`deleteNOdeStyle?${props.elementInfo.id}` + history, (e: any) => { 
          player.markers.remove([e])
          mitter.emit(`getData?${props.elementInfo.id}` + history, player.markers.getMarkers())
          message.success('删除节点成功!')
        })
        // 更换url
        mitter.on(`changeVideoDataURL?${props.elementInfo.id + history}`, (e: any) => { 
          const videoSrc = <HTMLVideoElement>document.getElementById(`video${props.elementInfo.id + history}_html5_api`)
          videoSrc.src = e 
          store.commit(MutationTypes.UPDATE_ELEMENT, 
          { 
            id: props.elementInfo.id, 
            props: { 
              src: e,
              marker: []
              // videoDuration: '' 
            } 
          })
          player.markers.reset([])
        })
        // 切换取
        mitter.on(`clickStyleItem?${props.elementInfo.id}` + history, () => {
          nextTick(() => {
            mitter.emit(`getData?${props.elementInfo.id}` + history, player.markers.getMarkers())
          })
        })
      }, { deep: true, immediate: true })
    })
    
    // if (props.elementInfo.actionPointKnowledges && props.elementInfo.actionPointKnowledges.length > 0) {
    //   knowledge = props.elementInfo.actionPointKnowledges[0]
    // }
    const changeCurrentTime = (index: any) => {
      player.pause()
      player.currentTime(props.elementInfo.marker[index].time)
      player.play()
      pointIndex.value = index
      mitter.emit('changePointIndex' + props.elementInfo.id, index)
      // if (props.elementInfo.actionPointKnowledges && props.elementInfo.actionPointKnowledges.length > 0) {
      //   knowledge = props.elementInfo.actionPointKnowledges[index]
      // }
    }

    const s_to_hs = (s: any) => {
      s = parseInt(s)
      //计算分钟
      //算法：将秒数除以60，然后下舍入，既得到分钟数
      let h
      h  =   Math.floor(s/60)
      //计算秒
      //算法：取得秒%60的余数，既得到秒数
      s  =   s%60
      //将变量转换为字符串
      h = h.toString()
      s = s.toString()
      //如果只有一位数，前面增加一个0
      // h = (h.length == 1) ? '0' + h : h
      s = (s.length == 1) ? '0' + s : s
      return h+':'+s
    }

    onUnmounted(() => {
      player.dispose()
      sessionStorage.setItem('player_history' + '_' + props.elementInfo.id, String(Number(history) + 1))
    })
    
    return {
      isCliping,
      handleClip,
      clipingImageElementId,
      shadowStyle,
      handleSelectElement,
      clipShape,
      imgPosition,
      filter,
      flipStyle,
      history,
      // viewportWrapperWidth,
      changeCurrentTime,
      pointIndex,
      s_to_hs
    }
  }
})
