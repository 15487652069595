
import { computed, defineComponent, PropType, reactive, onMounted, onUnmounted, getCurrentInstance, nextTick, ref } from 'vue'
import { PPTVideoElement } from '@/types/slides'
import useElementShadow from '@/views/components/element/hooks/useElementShadow'
import useElementFlip from '@/views/components/element/hooks/useElementFlip'
import useClipImage from './useClipImage'
import useFilter from './useFilter'

import VideoOutline from './VideoOutline/index.vue'


export default defineComponent({
  name: 'base-element-image',
  components: {
    VideoOutline
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTVideoElement>,
      required: true,
    },
  },
  setup(props) {
    const shadow = computed(() => props.elementInfo.shadow)
    const { shadowStyle } = useElementShadow(shadow)

    const flip = computed(() => props.elementInfo.flip)
    const { flipStyle } = useElementFlip(flip)
    
    const clip = computed(() => props.elementInfo.clip)
    const { clipShape, imgPosition } = useClipImage(clip)

    const filters = computed(() => props.elementInfo.filters)
    const { filter } = useFilter(filters)


    const mitter = getCurrentInstance()?.appContext.config.globalProperties.mitter
    const pointIndex: any = ref(0)
    mitter.on('changePointIndex' + props.elementInfo.id, (e: any) => {
      pointIndex.value = e
    })

    const s_to_hs = (s: any) => {
      s = parseInt(s)
      //计算分钟
      //算法：将秒数除以60，然后下舍入，既得到分钟数
      let h
      h  =   Math.floor(s/60)
      //计算秒
      //算法：取得秒%60的余数，既得到秒数
      s  =   s%60
      //将变量转换为字符串
      h = h.toString()
      s = s.toString()
      //如果只有一位数，前面增加一个0
      // h = (h.length == 1) ? '0' + h : h
      s = (s.length == 1) ? '0' + s : s
      return h+':'+s
    }
    
    return {
      imgPosition,
      filter,
      flipStyle,
      shadowStyle,
      clipShape,
      pointIndex,
      s_to_hs
    }
  },
})
