
import { computed, defineComponent, PropType } from 'vue'
import { PPTImageElement } from '@/types/slides'
import useClipImage from '../useClipImage'

import ImageRectOutline from './ImageRectOutline.vue'
import ImageEllipseOutline from './ImageEllipseOutline.vue'
import ImagePolygonOutline from './ImagePolygonOutline.vue'

export default defineComponent({
  name: 'image-outline',
  components: {
    ImageRectOutline,
    ImageEllipseOutline,
    ImagePolygonOutline,
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTImageElement>,
      required: true,
    },
  },
  setup(props) {
    const clip = computed(() => props.elementInfo.clip)
    const { clipShape } = useClipImage(clip)

    return {
      clipShape,
    }
  },
})
