
import { computed, defineComponent, ref, watch } from 'vue'
import { MutationTypes, useStore } from '@/store'
import { PPTElement, PPTElementOutline } from '@/types/slides'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

import ColorButton from './ColorButton.vue'

export default defineComponent({
  name: 'element-outline',
  components: {
    ColorButton,
  },
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore()
    const handleElement = computed<PPTElement>(() => store.getters.handleElement)

    const outline = ref<PPTElementOutline>()
    const hasOutline = ref(false)

    watch(handleElement, () => {
      if (!handleElement.value) return
      outline.value = 'outline' in handleElement.value ? handleElement.value.outline : undefined
      hasOutline.value = !!outline.value
    }, { deep: true, immediate: true })

    const { addHistorySnapshot } = useHistorySnapshot()

    const updateOutline = (outlineProps: Partial<PPTElementOutline>) => {
      const props = { outline: { ...outline.value, ...outlineProps } }
      store.commit(MutationTypes.UPDATE_ELEMENT, { id: handleElement.value.id, props })
      addHistorySnapshot()
    }

    const toggleOutline = (checked: boolean) => {
      if (checked) {
        const props = { outline: { width: 2, color: '#000', style: 'solid' } }
        store.commit(MutationTypes.UPDATE_ELEMENT, { id: handleElement.value.id, props })
      }
      else {
        store.commit(MutationTypes.REMOVE_ELEMENT_PROPS, { id: handleElement.value.id, propName: 'outline' })
      }
      addHistorySnapshot()
    }

    return {
      outline,
      hasOutline,
      toggleOutline,
      updateOutline,
    }
  },
})
