
import { defineComponent, ref } from 'vue'
import WritingBoard from '../components/WritingBoard.vue'

const writingBoardColors = ['#000000', '#ffffff', '#1e497b', '#4e81bb', '#e2534d', '#9aba60', '#8165a0', '#47acc5', '#f9974c']

export default defineComponent({
  name: 'writing-board-tool',
  components: {
    WritingBoard,
  },
  setup(props, { emit }) {
    const writingBoardRef = ref()
    const writingBoardColor = ref('#e2534d')
    const writingBoardModel = ref('pen')

    // 切换到画笔状态
    const changePen = () => {
      writingBoardModel.value = 'pen'
      emit('changeWritingBoardModel', writingBoardModel.value)
    }

    // 切换到橡皮状态
    const changeEraser = () => {
      writingBoardModel.value = 'eraser'
      emit('changeWritingBoardModel', writingBoardModel.value)
    }

    // 清除画布上的墨迹
    const clearCanvas = () => {
      writingBoardRef.value.clearCanvas()
    }

    // 修改画笔颜色，如果当前不处于画笔状态则先切换到画笔状态
    const changeColor = (color: string) => {
      if (writingBoardModel.value !== 'pen') writingBoardModel.value = 'pen'
      writingBoardColor.value = color
      emit('changeColor', writingBoardColor.value)
    }
    
    // 关闭写字板
    const closeWritingBoard = () => {
      emit('close')
    }

    return {
      writingBoardRef,
      writingBoardColors,
      writingBoardColor,
      writingBoardModel,
      changePen,
      changeEraser,
      clearCanvas,
      changeColor,
      closeWritingBoard,
    }
  },
})
