
import { defineComponent } from 'vue'
import { LINE_LIST, LinePoolItem } from '@/configs/lines'

import LinePointMarker from '@/views/components/element/LineElement/LinePointMarker.vue'

export default defineComponent({
  name: 'line-pool',
  components: {
    LinePointMarker,
  },
  setup(props, { emit }) {
    const lineList = LINE_LIST

    const selectLine = (line: LinePoolItem) => {
      emit('select', line)
    }

    return {
      lineList,
      selectLine,
    }
  },
})
