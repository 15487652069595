
export default {
  name: 'color-button',
  props: {
    color: {
      type: String,
      required: true,
    },
  },
}
